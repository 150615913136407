import type { UseQueryOptions } from '@tanstack/react-query';
import { monitorApi } from '../apis/apis.js';
import type { GetConstantResponseConstant } from '../../generated/activityhelper/api.js';
import type { LatestConstant } from '../types/constantTypes.js';
import { removeNulls } from '../../CPpro/Utils/tools.js';

export const constantsQuery: UseQueryOptions<LatestConstant[]> = {
  queryKey: ['constants'],
  queryFn: async () => {
    const { data } = await monitorApi.apiConstantGet({ });

    // map:
    const grouped = Map.groupBy(data.constants, (c) => c.key);
    const mapped: LatestConstant[] = grouped.values().toArray().map((v) => {
      const sortedByVersion = v.toSorted((a, b) => b.version - a.version); // desc
      if (sortedByVersion.length === 0) return null;
      const firstNotDisabled = sortedByVersion.find((c) => !c.disabledAt);
      return { latest: firstNotDisabled ?? sortedByVersion[0], versions: sortedByVersion };
    }).filter(removeNulls);

    return mapped;
  },
};

export const getConstantQuery = (key: string): UseQueryOptions<GetConstantResponseConstant> => ({
  queryKey: ['constant', key],
  queryFn: async () => {
    const { data } = await monitorApi.apiConstantKeyGet({ key });

    return data.constant;
  },
});
