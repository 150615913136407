import React, { useCallback } from 'react';
import { useForm, useStore } from '@tanstack/react-form';
import {
  Box, FormControl, Grid2, InputLabel, MenuItem, Select, TextField,
} from '@mui/material';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import type { PostConstantRequest } from '../../../../generated/activityhelper/api.js';
import { ZodValidation } from '../../../utils/index.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';

type ConstantAddFormProps = {
  defaultData?: PostConstantRequest,
  onSubmit: (data: PostConstantRequest) => void,
  lockKey?: boolean,
};

type FormConstant = Pick<PostConstantRequest, 'key' | 'value'> & {
  numValue: number | undefined,
  jsonValue: string | undefined,
};

const valueTypes = [
  'Tekst',
  'Tall',
  'Liste av tekst/tall',
  'JSON-objekt*',
  'JSON-string*',
] as const;
const filteredValueTypes = valueTypes.filter((v) => v !== 'JSON-objekt*' && v !== 'Liste av tekst/tall') as ValueType[];
type ValueType = typeof valueTypes[number];

const ConstantAddForm = (props: ConstantAddFormProps) => {
  const [valueType, setValueType] = React.useState<ValueType>('Tekst');
  const { defaultData, onSubmit, lockKey } = props;

  const onSubmitHandler = useCallback((value: FormConstant) => {
    if (value.numValue && valueType === 'Tall') {
      onSubmit({ key: value.key, value: value.numValue.toString(), json: value.numValue });
    } else if (value.jsonValue && (valueType === 'JSON-objekt*' || valueType === 'JSON-string*' || valueType === 'Liste av tekst/tall')) {
      const parsed = ZodValidation.stringToJSONSchema.safeParse(value.jsonValue);
      if (parsed.success) {
        onSubmit({ key: value.key, value: '', json: parsed.data });
      } else {
        // onSubmit({ key: value.key, value: value.value });
      }
    } else {
      onSubmit({ key: value.key, value: value.value });
    }
  }, [valueType, onSubmit]);

  const form = useForm<FormConstant>({
    defaultValues: defaultData ? { ...defaultData, numValue: undefined, jsonValue: undefined } : undefined,
    onSubmit: ({ value }) => {
      onSubmitHandler(value);
    },
  });

  const valueNum = useStore(form.store, (state) => state.values.numValue);
  const valueJson = useStore(form.store, (state) => state.values.jsonValue);

  return (
    <Box>
      <form onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      >
        <Grid2 container spacing={1} direction="column">
          <Grid2>
            <form.Field name="key" validatorAdapter={zodValidator()} validators={{ onChange: z.string().min(1) }}>
              {(field) => (
                <TextField
                  label="Nøkkel"
                  value={field.state.value ?? ''}
                  onChange={(e) => field.handleChange(e.target.value)}
                  disabled={lockKey}
                  sx={{ width: 400 }}
                  error={!!field.state.meta.errors.length}
                  helperText={field.state.meta.errors.join(', ')}
                  required
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <FormControl>
              <InputLabel id="select_type_label" shrink sx={{ backgroundColor: 'white' }}>
                Verditype
              </InputLabel>
              <Select
                id="select_type"
                labelId="select_type_label"
                label="Verditype"
                value={valueType}
                onChange={(e) => setValueType(e.target.value as ValueType)}
                fullWidth
              >
                {filteredValueTypes.map((v) => <MenuItem key={v} value={v}>{v}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid2>
          {valueType === 'Tekst' ? (
            <Grid2>
              <form.Field
                name="value"
                validatorAdapter={zodValidator()}
                validators={{ onChange: z.string().min(1) }}
              >
                {(field) => (
                  <TextField
                    label="Verdi"
                    value={field.state.value ?? ''}
                    onChange={(e) => field.handleChange(e.target.value)}
                    fullWidth
                    error={!!field.state.meta.errors.length}
                    helperText={field.state.meta.errors.join(', ')}
                    required
                    multiline
                    minRows={2}
                    maxRows={10}
                  />
                )}
              </form.Field>
            </Grid2>
          ) : null}
          {valueType === 'Tall' ? (
            <Grid2>
              <form.Field
                name="numValue"
                validatorAdapter={zodValidator()}
                validators={{
                  onChange: z.number(),
                }}
              >
                {(field) => (
                  <TextField
                    type="number"
                    label="Verdi (tall)"
                    value={field.state.value ?? ''}
                    onChange={(e) => field.handleChange(Number(e.target.value))}
                    disabled={!!valueJson}
                    error={!!field.state.meta.errors.length}
                    helperText={field.state.meta.errors.join(', ')}
                    required
                  />
                )}
              </form.Field>
            </Grid2>
          ) : null}
          {valueType === 'JSON-string*' ? (
            <Grid2>
              <form.Field
                name="jsonValue"
                validatorAdapter={zodValidator()}
                validators={{
                  onChange: ZodValidation.stringIsJSONSchema,
                }}
              >
                {(field) => (
                  <TextField
                    multiline
                    minRows={2}
                    maxRows={10}
                    label="Verdi (json)"
                    value={valueJson ?? ''}
                    onChange={(e) => field.handleChange(e.target.value)}
                    disabled={valueNum !== undefined}
                    fullWidth
                    error={!!field.state.meta.errors.length}
                    helperText={field.state.meta.errors.join(', ')}
                    required
                  />
                )}
              </form.Field>
            </Grid2>
          ) : null}
          <Grid2 container sx={{ justifyContent: 'flex-end' }}>
            <form.Subscribe selector={((state) => [state.canSubmit, state.isSubmitting])}>
              {([canSubmit, isSubmitting]) => (
                <LoadingButton
                  save
                  buttonProps={{
                    variant: 'contained',
                    type: 'submit',
                    loading: isSubmitting,
                    disabled: !canSubmit || isSubmitting,
                    color: 'success',
                  }}
                >
                  Lagre
                </LoadingButton>
              )}
            </form.Subscribe>
          </Grid2>

        </Grid2>
      </form>
    </Box>
  );
};

export default ConstantAddForm;
