import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Box, Button, IconButton, Stack,
  Tooltip,
} from '@mui/material';
import {
  AddCircle, Delete, Edit, Info,
} from '@mui/icons-material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DateUtils } from '../../../../../sharedutils/index.js';
import type { GetInstallItemUsageResponseInner } from '../../../../../generated/activityhelper/api.js';
import { InstallItemUsageQueries } from '../../../../queries/index.js';
import MonitorPageTitle from '../../../monitor/MonitorPageTitle.js';
import GenericTable from '../../../table/GenericTable.js';
import InstallItemUsageAddDialog from './InstallItemUsageAddDialog.js';
import InstallItemUsageEditContent from './InstallItemUsageEditContent.js';
import { InstallItemUsageMutations } from '../../../../mutations/index.js';
import InstallItemUsageAlternativeAddContent from './InstallItemUsageAlternativeAddContent.js';
import InstallItemUsageInfoDialogContent from './InstallItemUsageInfoDialogContent.js';
import { monitorApi } from '../../../../apis/apis.js';

const columnHelper = createColumnHelper<GetInstallItemUsageResponseInner>();

/**
 * MISSING:
 * description
 * last cost mrp
 * salesprice 24so
 * discount 24so (cannot fetch -> default to 50%?)
 */

const columns = [
  columnHelper.accessor((a) => a.itemNumber, {
    header: 'Produkt nr',
    id: 'itemNumber',
  }),
  columnHelper.accessor((a) => a.description, {
    header: 'Beskrivelse',
    id: 'description',
  }),
  // columnHelper.accessor((a) => a.discount, {
  //   header: 'Manuell rabatt',
  //   id: 'discount',
  // }),
  columnHelper.accessor((a) => a.staticQuantity, {
    header: 'Statisk antall',
    id: 'staticQuantity',
  }),
  // columnHelper.accessor((a) => a.flatAddition, {
  //   header: 'Flatt tillegg',
  //   id: 'flatAddition',
  // }),
  columnHelper.accessor((a) => a.storageUnitConversion, {
    header: 'Lagerenhetsfaktor',
    id: 'storageUnitConversion',
  }),
  columnHelper.accessor((a) => a.createdAt, {
    header: 'Opprettet',
    id: 'createdAt',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  columnHelper.accessor((a) => a.updatedAt, {
    header: 'Oppdatert',
    id: 'updatedAt',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  columnHelper.accessor((a) => a.alternatives, {
    header: 'Alternativ',
    id: 'alternatives',
    cell: (info) => info.getValue()?.length ?? 0,
  }),
  columnHelper.display({
    header: 'Actions',
    id: 'actions',
    cell: (ctx) => (
      <>
        <Tooltip title="Se detaljert info og endre alternativ">
          <IconButton size="small" onClick={() => ctx.table.options.meta?.openRowDialog(ctx)}>
            <Info color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Legg til alternativ">
          <IconButton size="small" onClick={() => ctx.table.options.meta?.openAddDialog(ctx)}>
            <AddCircle color="success" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Endre vareforbruket (ikke alternativ - bruk info-knappen)">
          <IconButton size="small" onClick={() => ctx.table.options.meta?.openEditDialog(ctx)}>
            <Edit color="warning" />
          </IconButton>
        </Tooltip>
        <IconButton size="small" onClick={() => ctx.table.options.meta?.openDeleteDialog(ctx)}>
          <Delete color="error" />
        </IconButton>
      </>
    ),
  }),
  columnHelper.group({
    header: 'Antall per meter',
    columns: Array(18).fill(1).map((a, idx) => a + 2 + idx).map((m) => columnHelper.display({
      header: `${m}`,
      id: `meter${m}`,
      cell: (info) => {
        const value = info.row.original.quantities.find((q) => q.meter === m)?.quantity;
        return value ?? 'N/A';
      },
    })),
  }),
];

const ERPInstallItemUsagePage = () => {
  const [addOpen, setAddOpen] = React.useState(false);
  const queryClient = useQueryClient();
  const itemUsages = useQuery(InstallItemUsageQueries.installItemUsagesQuery);

  const deleteItemUsageMutation = useMutation({
    ...InstallItemUsageMutations.deleteItemUsageMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(InstallItemUsageQueries.installItemUsagesQuery);
    },
  });

  const forceUpdateMutation = useMutation({
    mutationFn: async () => {
      await monitorApi.apiErpInstallItemUsageGet({ ignoreCache: 'true' });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(InstallItemUsageQueries.installItemUsagesQuery);
    },
  });

  return (
    <Box>
      <MonitorPageTitle title="Vareforbruk" />
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        <Button variant="contained" color="success" onClick={() => setAddOpen(true)}>Legg til vareforbruk</Button>
        <Button disabled={forceUpdateMutation.isPending} variant="contained" color="warning" onClick={() => forceUpdateMutation.mutateAsync()}>Tving oppdatering fra MRP</Button>
      </Stack>
      <GenericTable
        id="installItemUsage"
        columns={columns}
        data={itemUsages.data ?? []}
        editDialogProps={{
          title: 'Registrer vareforbruk',
          Content: InstallItemUsageEditContent,
        }}
        deleteDialogProps={{
          title: 'Slett vareforbruk, er du sikker?',
          onDelete: async (data, close) => {
            if (data) {
              await deleteItemUsageMutation.mutateAsync({ itemNumber: data.itemNumber });
              close();
            }
          },
        }}
        addDialogProps={{
          title: 'Registrer vareforbruksalternativ',
          Content: InstallItemUsageAlternativeAddContent,
        }}
        rowDialogProps={{
          title: (info) => `Vareforbruk info: ${info.row?.itemNumber}`,
          Content: InstallItemUsageInfoDialogContent,
          maxWidth: 'xl',
        }}
      />
      <InstallItemUsageAddDialog open={addOpen} onClose={() => setAddOpen(false)} />
    </Box>
  );
};

export default ERPInstallItemUsagePage;
