import type { Theme } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Box, Grid2,
  Divider, IconButton, MenuItem, Select, Tooltip, Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useCallback, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { DeleteForever, ExpandMore, Replay } from '@mui/icons-material';
import { MonitorQueries } from '../../../queries/index.js';
import GenericTable from '../../table/GenericTable.js';
import type { MonitorTypes } from '../../monitor/index.js';
import { DateUtils } from '../../../../sharedutils/index.js';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import { MonitorMutations } from '../../../mutations/index.js';
import ConfirmDialog from '../../common/dialogs/ConfirmDialog.js';
import { useCPproUserContext } from '../../../contexts/CpproUserContext.js';
import { AccessTypes } from '../../../types/index.js';
import { AccessRole } from '../../../../generated/activityhelper/api.js';

const columnHelper = createColumnHelper<MonitorTypes.QueueWithTasks['tasks'][0]>();

const taskColumns = [
  columnHelper.accessor((row) => row.httpRequest?.url, {
    header: 'Url',
    id: 'url',
  }),
  columnHelper.accessor((row) => row.httpRequest?.httpMethod, {
    header: 'HTTP Method',
    id: 'method',
  }),
  columnHelper.accessor((row) => row.dispatchCount, {
    header: 'Dispatch Count',
    id: 'dispatchCount',
  }),
  columnHelper.accessor((row) => row.responseCount, {
    header: 'Response Count',
    id: 'responseCount',
  }),
  columnHelper.accessor((row) => row.createTime, {
    header: 'Create Time',
    id: 'createTime',
    cell: (row) => DateUtils.getLocalizedDateTime(row.getValue()),
  }),
  columnHelper.accessor((row) => row.scheduleTime, {
    header: 'Schedule Time',
    id: 'scheduleTime',
    cell: (row) => DateUtils.getLocalizedDateTime(row.getValue()),
  }),
  columnHelper.accessor((row) => row.lastAttempt, {
    header: 'Last Attempt',
    id: 'lastAttempt',
    cell: (row) => {
      const value = row.getValue();
      const date = value?.dispatchTime;
      const message = value?.responseStatus?.message;
      if (!date || !message) return 'No attempt';

      return `${DateUtils.getLocalizedDateTime(date)}. Message: ${message}`;
    },
  }),
];

const getTaskColor = (length: number, theme: Theme) => {
  if (length === 0) return theme.palette.success.main;
  if (length <= 10) return theme.palette.warning.light;
  return theme.palette.error.dark;
};

// TODO Show data/body for task?

const getActionColumn = (onRunClick: (path: string) => void, onDeleteClick: (path: string) => void) => columnHelper.accessor((row) => row.path, {
  id: 'actions',
  header: 'Actions',
  cell: (row) => {
    const path = row.getValue();

    return (
      (
        <Grid2
          container
          spacing={1}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Grid2>
            <Tooltip title="Run">
              <IconButton onClick={() => onRunClick(path)}>
                <Replay />
              </IconButton>
            </Tooltip>
          </Grid2>
          <Grid2>
            <Tooltip title="Delete">
              <IconButton onClick={() => onDeleteClick(path)}>
                <DeleteForever />
              </IconButton>
            </Tooltip>
          </Grid2>
        </Grid2>
      )
    );
  },
});

const MonitorTasksPage = () => {
  const { user } = useCPproUserContext();
  const [selectedQueue, setSelectedQueue] = React.useState<string>('');

  const [confirmRunItem, setConfirmRunItem] = React.useState<string | null>(null);
  const [confirmDeleteItem, setConfirmDeleteItem] = React.useState<string | null>(null);

  const { data: queues } = useQuery(MonitorQueries.queuesWithTasksQuery);

  const { mutate: runTaskMutate } = useMutation(MonitorMutations.runTaskMutation);
  const { mutate: deleteTaskMutate } = useMutation(MonitorMutations.deleteTaskMutation);

  const actionColumn = getActionColumn(
    (path) => runTaskMutate({ taskPathCommand: { taskPath: path } }),
    (path) => deleteTaskMutate({ taskPathCommand: { taskPath: path } }),
  );

  const columns = useMemo(() => {
    if (user?.checkRole(AccessRole.Monitor, AccessTypes.AccessRoleLevel.WRITE)) {
      return [...taskColumns, actionColumn];
    }
    return taskColumns;
  }, [actionColumn, user]);

  const queueNames = useMemo(() => queues?.map((queue) => queue.name), [queues]);
  const selectedQueueTasks = useMemo(() => queues?.find((queue) => queue.name === selectedQueue)?.tasks, [queues, selectedQueue]);

  const onConfirmRun = useCallback(() => {
    if (confirmRunItem) {
      runTaskMutate({ taskPathCommand: { taskPath: confirmRunItem } });
      setConfirmRunItem(null);
    }
  }, [confirmRunItem, runTaskMutate]);

  const onConfirmDelete = useCallback(() => {
    if (confirmDeleteItem) {
      deleteTaskMutate({ taskPathCommand: { taskPath: confirmDeleteItem } });
      setConfirmDeleteItem(null);
    }
  }, [confirmDeleteItem, deleteTaskMutate]);

  return (
    (
      <Box>
        <MonitorPageTitle title="Webhooks / Google Cloud Tasks" refreshQuery={MonitorQueries.queuesWithTasksQuery} />
        <Typography variant="h5" gutterBottom>Queues</Typography>
        <Box>
          {queues?.map((queue) => (
            <Accordion key={queue.name}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography sx={{
                  fontWeight: 'bold',
                }}
                >
                  {queue.name}
                </Typography>
              &nbsp;|&nbsp;
                {queue.state}
              &nbsp;|&nbsp;
                <Typography sx={{
                  color: (theme) => getTaskColor(queue.tasks.length, theme),
                }}
                >
                  {'Tasks: '}
                  {queue.tasks.length}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid2 container spacing={2}>
                  <Grid2>
                    <Typography>
                      {'Status: '}
                      {queue.state}
                    </Typography>
                  </Grid2>
                  <Grid2>
                    <Typography>
                      {'Tasks in queue: '}
                      {queue.tasks.length}
                    </Typography>
                  </Grid2>
                </Grid2>
                <Typography
                  sx={{
                    fontWeight: 500,
                    mt: 1,
                  }}
                >
                  Retry config:
                </Typography>
                <Grid2 container spacing={2}>
                  <Grid2>
                    <Typography>
                      {'Max attempts: '}
                      {queue.retryConfig?.maxAttempts}
                    </Typography>
                  </Grid2>
                  {queue.retryConfig?.maxRetryDuration?.seconds ? (
                    <Grid2>
                      <Typography>
                        {'Max retry duration: '}
                        {queue.retryConfig?.maxRetryDuration?.seconds}
                      </Typography>
                    </Grid2>
                  ) : null}
                  <Grid2>
                    <Typography>
                      {'Min backoff: '}
                      {queue.retryConfig?.minBackoff?.seconds}
                    </Typography>
                  </Grid2>
                  <Grid2>
                    <Typography>
                      {'Max backoff: '}
                      {queue.retryConfig?.maxBackoff?.seconds}
                    </Typography>
                  </Grid2>
                  <Grid2>
                    <Typography>
                      {'Max doublings: '}
                      {queue.retryConfig?.maxDoublings}
                    </Typography>
                  </Grid2>
                </Grid2>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Typography variant="h5" gutterBottom>Tasks</Typography>
        <Select
          size="small"
          value={selectedQueue}
          onChange={(e) => setSelectedQueue(e.target.value)}
          displayEmpty
          sx={{ mb: 2 }}
        >
          <MenuItem key="empty" value="">No queue</MenuItem>
          {queueNames?.map((queue) => (
            <MenuItem key={queue} value={queue}>{queue}</MenuItem>
          ))}
        </Select>
        {selectedQueueTasks?.length ? (
          <GenericTable data={selectedQueueTasks} columns={columns} pagination />
        ) : (
          <Typography sx={{
            fontWeight: 'bold',
          }}
          >
            {selectedQueueTasks === undefined ? 'No selected queue' : 'No tasks in queue'}
          </Typography>
        )}
        <Divider sx={{ mb: 2, mt: 2 }} />
        <Typography variant="h5" gutterBottom>Create task manually (TODO?)</Typography>
        <ConfirmDialog open={!!confirmRunItem} onClose={() => setConfirmRunItem(null)} onConfirm={onConfirmRun} buttonText="Run" />
        <ConfirmDialog open={!!confirmDeleteItem} onClose={() => setConfirmDeleteItem(null)} onConfirm={onConfirmDelete} buttonText="Delete" />
      </Box>
    )
  );
};

export default MonitorTasksPage;
