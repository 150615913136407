import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { IconButton, Tooltip } from '@mui/material';
import {
  AddCircle,
  Check, Delete, Info, Stop,
} from '@mui/icons-material';
import type { LatestConstant } from '../../../types/constantTypes.js';
import { DateUtils } from '../../../../sharedutils/index.js';
import type { GetConstantsResponseConstantsInner } from '../../../../generated/activityhelper/api.js';

const columnHelper = createColumnHelper<LatestConstant>();
const versionColumnHelper = createColumnHelper<GetConstantsResponseConstantsInner>();

export const mainConstantColumns = [
  columnHelper.accessor((a) => a.latest.key, {
    header: 'Nøkkel',
    id: 'key',
  }),
  columnHelper.accessor((a) => a.latest.version, {
    header: 'Versjon',
    id: 'version',
  }),
  columnHelper.accessor((a) => a.latest.value, {
    header: 'Verdi',
    id: 'value',
  }),
  columnHelper.accessor((a) => a.latest.json, {
    header: 'Data',
    id: 'data',
    cell: (i) => (i.getValue() ? <code>{JSON.stringify(i.getValue(), null, 2)}</code> : null),
  }),
  columnHelper.accessor((a) => a.latest.createdAt, {
    header: 'Opprettet',
    id: 'createdAt',
    cell: (i) => DateUtils.getLocalizedDateTime(i.getValue()),
  }),
  columnHelper.accessor((a) => a.latest.createdBy, {
    header: 'Opprettet av',
    id: 'createdBy',
  }),
  columnHelper.display({
    header: 'Status',
    id: 'status',
    cell: (i) => {
      const isDisabled = !!i.row.original.latest.disabledAt;
      return isDisabled ? <Stop color="warning" /> : <Check color="success" />;
    },
  }),
  columnHelper.accessor((a) => a.latest.disabledAt, {
    header: 'Avslått',
    id: 'disabledAt',
    cell: (i) => DateUtils.getLocalizedDateTime(i.getValue()),
  }),
  columnHelper.accessor((a) => a.latest.disabledBy, {
    header: 'Avslått av',
    id: 'disabledBy',
  }),
  columnHelper.accessor((a) => a.latest.isUsed, {
    header: 'Blir brukt?',
    id: 'isUsed',
    cell: (i) => (i.getValue() ? 'Ja' : 'Nei'),
  }),
  columnHelper.display({
    header: 'Actions',
    id: 'actions',
    enableSorting: false,
    cell: (i) => (
      <>
        <IconButton size="small" onClick={() => i.table.options.meta?.openRowDialog(i)}>
          <Info color="primary" />
        </IconButton>
        <Tooltip title="Legg til ny versjon">
          <IconButton size="small" onClick={() => i.table.options.meta?.openEditDialog(i)}>
            <AddCircle color="success" />
          </IconButton>
        </Tooltip>
        <IconButton disabled={i.row.original.latest.isUsed} size="small" onClick={() => i.table.options.meta?.openDeleteDialog(i)}>
          <Delete color="error" />
        </IconButton>
      </>
    ),
  }),
];

export const constantVersionsColumns = [
  versionColumnHelper.accessor((a) => a.key, {
    header: 'Nøkkel',
    id: 'key',
  }),
  versionColumnHelper.accessor((a) => a.version, {
    header: 'Versjon',
    id: 'version',
  }),
  versionColumnHelper.accessor((a) => a.value, {
    header: 'Verdi',
    id: 'value',
  }),
  versionColumnHelper.accessor((a) => a.json, {
    header: 'Data',
    id: 'data',
    cell: (i) => (i.getValue() ? <code>{JSON.stringify(i.getValue(), null, 2)}</code> : null),
  }),
  versionColumnHelper.accessor((a) => a.createdAt, {
    header: 'Opprettet',
    id: 'createdAt',
    cell: (i) => DateUtils.getLocalizedDateTime(i.getValue()),
  }),
  versionColumnHelper.accessor((a) => a.createdBy, {
    header: 'Opprettet av',
    id: 'createdBy',
  }),
  versionColumnHelper.display({
    header: 'Status',
    id: 'status',
    cell: (i) => {
      const isDisabled = !!i.row.original.disabledAt;
      return isDisabled ? <Stop color="warning" /> : <Check color="success" />;
    },
  }),
  versionColumnHelper.accessor((a) => a.disabledAt, {
    header: 'Avslått',
    id: 'disabledAt',
    cell: (i) => DateUtils.getLocalizedDateTime(i.getValue()),
  }),
  versionColumnHelper.accessor((a) => a.disabledBy, {
    header: 'Avslått av',
    id: 'disabledBy',
  }),
];
