import {
  Dialog, DialogContent, DialogTitle,
} from '@mui/material';
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import InstallItemUsageAlternativeAddEditForm from './InstallItemUsageAlternativeAddEditForm.js';
import { InstallItemUsageMutations } from '../../../../mutations/index.js';
import { InstallItemUsageQueries } from '../../../../queries/index.js';

type InstallItemUsageAlternativeAddDialogProps = {
  open: boolean,
  onClose: (action: 'close' | 'submit') => void,
  itemNumber: string,
};

const InstallItemUsageAlternativeAddDialog = (props: InstallItemUsageAlternativeAddDialogProps) => {
  const { open, onClose, itemNumber } = props;

  const queryClient = useQueryClient();
  const addMutation = useMutation({
    ...InstallItemUsageMutations.addItemUsageAlternativeMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(InstallItemUsageQueries.installItemUsagesQuery);
      onClose('submit');
    },
  });

  return (
    <Dialog open={open} onClose={() => onClose('close')} maxWidth="md" fullWidth>
      <DialogTitle>Legg til vareforbruksalternativ</DialogTitle>
      <DialogContent>
        <InstallItemUsageAlternativeAddEditForm
          onSubmit={(data) => addMutation.mutateAsync({
            itemNumber,
            postInstallItemUsageAlternativeRequest: data,
          })}
          type="add"
        />
      </DialogContent>
    </Dialog>
  );
};

export default InstallItemUsageAlternativeAddDialog;
