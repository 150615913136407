import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import {
  Backdrop, Button, CircularProgress, Divider, Link,
  Paper,
  Typography, Box, Grid2,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import type { CloudTask, ErpSyncOrderWithLinkAndTasks } from '../../../../generated/activityhelper/api.js';
import { DateUtils } from '../../../../sharedutils/index.js';
import { ERPQueries } from '../../../queries/index.js';
import GenericTable from '../../table/GenericTable.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';
import { DateRange } from '../../table/DateRangeFilter.js';
import ERPOverviewTaskDialogContent from '../../erp/ERPOverviewTaskDialogContent.js';
import ERPOverviewFilter from '../../erp/ERPOverviewFilter.js';

const columnHelper = createColumnHelper<ErpSyncOrderWithLinkAndTasks>();

const getLinkText = (link: string | undefined) => {
  if (!link) return '';
  const split = link.split('=');
  if (split.length >= 2) return split[1];
  return link;
};

const columns = [
  // columnHelper.accessor((row) => row.cpproId, {
  //   header: 'CPPro Salg ID',
  //   id: 'cpproId',
  // }),
  columnHelper.accessor((row) => row.salesOrderLink, {
    header: 'Salgsordre',
    id: 'salesOrderLink',
    cell: (info) => <Link href={info.getValue()} target="_blank" rel="noreferrer noopener">{getLinkText(info.getValue())}</Link>,
    filterFn: 'includesString',
    // filterFn: (row, id, value) => {
    //   const link = getLinkText(row.getValue(id));
    //   return link
    // }
  }),
  // columnHelper.accessor((row) => row.cpproInstallId, {
  //   header: 'CPPro Montering ID',
  //   id: 'cpproInstallId',
  // }),
  columnHelper.accessor((row) => row.installOrderLink, {
    header: 'Monteringsordre',
    id: 'installOrderLink',
    cell: (info) => <Link href={info.getValue()} target="_blank" rel="noreferrer noopener">{getLinkText(info.getValue())}</Link>,
    filterFn: 'includesString',
  }),
  columnHelper.accessor((row) => row.orderType, {
    header: 'Ordretype',
    id: 'orderType',
  }),
  columnHelper.accessor((row) => row.created, {
    header: 'Dato opprettet',
    id: 'created',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  columnHelper.accessor((row) => row.acErpId, {
    header: '24SO AC(T) Ordre ID',
    id: 'acErpId',
    filterFn: 'equals',
  }),
  // columnHelper.accessor((row) => row.isACT, {
  //   header: 'ACT?',
  //   id: 'isACT',
  //   cell: (info) => (info.getValue() ? 'Ja' : 'Nei'),
  // }),
  columnHelper.accessor((row) => row.fjErpId, {
    header: '24SO FJ Klima Hjelpeordre ID',
    id: 'fjErpId',
    filterFn: 'nullEquals',
  }),
  columnHelper.accessor((row) => row.finalErpId, {
    header: '24SO FJ Klima Final ID',
    id: 'finalErpId',
    filterFn: 'nullEquals',
  }),
  columnHelper.accessor((row) => row.mrpSaleId, {
    header: 'Masterplan Ordre ID',
    id: 'mrpSaleId',
    filterFn: 'nullEquals',
  }),
  columnHelper.accessor((row) => row.mrpShipId, {
    header: 'Masterplan Ship ID',
    id: 'mrpShipId',
    filterFn: 'nullEquals',
  }),
  columnHelper.accessor((row) => row.mrpInvoiceId, {
    header: 'Masterplan Faktura ID',
    id: 'mrpInvoiceId',
    filterFn: 'nullEquals',
  }),
  columnHelper.accessor((row) => row.mrpInvoiceDate, {
    header: 'Masterplan Fakturert Dato',
    id: 'mrpInvoiceDate',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  columnHelper.accessor((row) => row.acErpInvoiced, {
    header: '24SO AC(T) Fakturert?',
    id: 'acErpInvoiced',
    cell: (info) => (info.getValue() ? 'Ja' : ''),
  }),
  columnHelper.accessor((row) => row.acErpInvoiceDate, {
    header: '24SO AC(T) Fakturert Dato',
    id: 'acErpInvoiceDate',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  columnHelper.accessor((row) => row.fjErpInvoiced, {
    header: '24SO FJ Klima Ordre Fakturert?',
    id: 'fjErpInvoiced',
    cell: (info) => (info.getValue() ? 'Ja' : ''),
  }),
  columnHelper.accessor((row) => row.fjErpInvoiceDate, {
    header: '24SO FJ Klima Fakturert Dato',
    id: 'fjErpInvoiceDate',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  columnHelper.accessor((row) => row.acOrderStatus, {
    header: '24SO AC(T) Siste ordrestatus',
    id: 'acOrderStatus',
    filterFn: 'nullEquals',
  }),
  columnHelper.accessor((row) => row.finalFJOrderStatus, {
    header: '24SO FJ Klima Siste ordrestatus',
    id: 'finalFJOrderStatus',
    filterFn: 'nullEquals',
  }),
  columnHelper.accessor((row) => row.fjOrderStatus, {
    header: '24SO FJ Klima Hjelpeordre Siste ordrestatus',
    id: 'fjOrderStatus',
  }),
  columnHelper.accessor((row) => row.tasks, {
    header: 'Tasks',
    id: 'tasks',
    cell: (info) => (info.getValue().length ? (<Button onClick={() => info.table.options.meta?.openRowDialog(info)}>Vis detaljer</Button>) : ''),
    filterFn: (row, columnId, filterValue) => (filterValue
      ? (row.getValue(columnId) as CloudTask[] ?? []).some((task) => task.httpRequest?.url?.includes(filterValue))
      : true),
  }),
  columnHelper.accessor((row) => row.manualInvoicing, {
    header: 'Manuell Fakturering?',
    id: 'manualInvoicing',
    cell: (info) => (info.getValue() ? 'Ja' : ''),
  }),
  columnHelper.accessor((row) => row.error, {
    header: 'Error?',
    id: 'errors',
  }),
];

const startOfMonth = DateUtils.convertToMUI(dayjs().startOf('month').tz('Europe/Oslo').toDate());
const now = DateUtils.convertToMUI(new Date());

const ERPOverview = () => {
  const [from, setFrom] = React.useState<string | undefined>(startOfMonth);
  const [to, setTo] = React.useState<string | undefined>(now);

  const [query, setQuery] = React.useState(ERPQueries.getErpSyncOrdersQuery({
    startTime: DateUtils.convertFromMUI(from),
    endTime: DateUtils.convertFromMUI(to),
  }));

  const { data, isLoading } = useQuery(query);

  const onSearch = () => {
    setQuery(ERPQueries.getErpSyncOrdersQuery({ startTime: DateUtils.convertFromMUI(from), endTime: DateUtils.convertFromMUI(to) }));
  };

  return (
    (
      <Box>
        <Paper sx={{ padding: 2 }}>
          <Box>
            <Typography variant="h6" gutterBottom>Søk:</Typography>
            <Grid2
              container
              spacing={2}
              sx={{
                alignItems: 'center',
              }}
            >
              <Grid2>
                <DateRange
                  valueStart={from}
                  valueEnd={to}
                  onChangeEnd={(e) => setTo(e.target.value)}
                  onChangeStart={(e) => setFrom(e.target.value)}
                  datetime
                />
              </Grid2>
              <Grid2>
                <LoadingButton buttonProps={{ variant: 'contained', loading: isLoading, onClick: onSearch }}>Søk</LoadingButton>
              </Grid2>
            </Grid2>
          </Box>
          <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
          <Box>
            <GenericTable
              Filter={ERPOverviewFilter}
              id="erpOverview"
              columns={columns}
              data={data?.erpSyncOrders ?? []}
              initialSortingState={[{ id: 'mrpInvoiceDate', desc: true }, { id: 'created', desc: true }]}
              pagination
              rowDialogProps={{
                Content: ERPOverviewTaskDialogContent,
                title: 'Detaljer tasks',
                maxWidth: 'lg',
              }}
            />
          </Box>
        </Paper>
        <Backdrop open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    )
  );
};

export default ERPOverview;
