import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ConstantAddForm from './ConstantAddForm.js';
import { ConstantMutations } from '../../../mutations/index.js';
import { ConstantQueries } from '../../../queries/index.js';

type Props = {
  open: boolean,
  onClose: () => void,
  addVersion?: { key: string },
};

const ConstantAddDialog = (props: Props) => {
  const { open, onClose, addVersion } = props;
  const queryClient = useQueryClient();
  const addMutation = useMutation({
    ...ConstantMutations.addConstantMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(ConstantQueries.constantsQuery);
      onClose();
    },
  });
  const addVersionMutation = useMutation({
    ...ConstantMutations.addConstantVersionMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(ConstantQueries.constantsQuery);
      onClose();
    },
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Legg til ny konstant
        {addVersion ? ' versjon' : ''}
      </DialogTitle>
      <DialogContent>
        <ConstantAddForm
          onSubmit={(data) => {
            if (addVersion) {
              addVersionMutation.mutateAsync({ key: data.key, postConstantRequest: data });
            } else {
              addMutation.mutateAsync(data);
            }
          }}
          lockKey={!!addVersion}
          defaultData={addVersion ? { key: addVersion.key, value: '' } : undefined}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ConstantAddDialog;
