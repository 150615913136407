import {
  Dialog, DialogContent, DialogTitle,
} from '@mui/material';
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import InstallItemUsageAddEditForm from './InstallItemUsageAddEditForm.js';
import { InstallItemUsageMutations } from '../../../../mutations/index.js';
import { InstallItemUsageQueries } from '../../../../queries/index.js';

type InstallItemUsageAddDialogProps = {
  open: boolean,
  onClose: () => void,
};

const InstallItemUsageAddDialog = (props: InstallItemUsageAddDialogProps) => {
  const { open, onClose } = props;

  const queryClient = useQueryClient();
  const addMutation = useMutation({
    ...InstallItemUsageMutations.addItemUsageMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(InstallItemUsageQueries.installItemUsagesQuery);
      onClose();
    },
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Legg til vareforbruk</DialogTitle>
      <DialogContent>
        <InstallItemUsageAddEditForm onSubmit={(data) => addMutation.mutateAsync({ ...data, alternatives: [] })} type="add" />
      </DialogContent>
    </Dialog>
  );
};

export default InstallItemUsageAddDialog;
