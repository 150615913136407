import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import type { SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Box, Button, Divider, FormControl, Grid2, IconButton, InputLabel, MenuItem, Select, Tab, TextField, Typography,
} from '@mui/material';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
  Cancel, CheckCircle, Edit, Help, RemoveCircle,
} from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { ERPQueries } from '../../../queries/index.js';
import type { DialogRowElementProps, GenericTableFilterProps } from '../../table/GenericTable.js';
import GenericTable from '../../table/GenericTable.js';
import {
  type DateCommand,
  type DefaultApiApiErpMrpPickErrorPickErrorIdPutRequest, type GetMrpPickErrorsRequest,
  GetMrpPickErrorsRequestStatusEnum, type MrpPickErrorListInner,
} from '../../../../generated/activityhelper/api.js';

import { DateUtils } from '../../../../sharedutils/index.js';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import AutocompleteFilter from '../../table/filters/AutocompleteFilter.js';
import SelectFilter from '../../table/SelectFilter.js';
import useSearchQuery from '../../../hooks/useSearchQuery.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';
import { ERPMutations } from '../../../mutations/index.js';
import 'core-js/actual/iterator/to-array';
import DateRangeFilter from '../../table/DateRangeFilter.js';

const columnHelper = createColumnHelper<MrpPickErrorListInner>();

const columns = [
  columnHelper.accessor((row) => row.mrpId, {
    header: 'Referanse Masterplan',
    id: 'mrpId',
    filterFn: 'equals',
  }),
  columnHelper.accessor((row) => row.orderType, {
    header: 'Ordretype',
    id: 'orderType',
  }),
  columnHelper.accessor((row) => row.date, {
    header: 'Dato avvik',
    id: 'date',
    sortDescFirst: true,
    filterFn: 'isWithinRange',
    sortingFn: 'datetime',
    enableSorting: true,
    cell: (info) => DateUtils.getLocalizedDate(info.getValue()),
  }),
  columnHelper.accessor((row) => row.lastErrorAt, {
    header: 'Siste avvik',
    id: 'lastDate',
    sortDescFirst: true,
    sortingFn: 'datetime',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  columnHelper.accessor((row) => row.status, {
    header: 'Status',
    id: 'status',
    cell: (info) => {
      switch (info.getValue()) {
        case 'OK':
          return <CheckCircle color="success" />;
        case 'ERROR':
          return <Cancel color="error" />;
        case 'UNKNOWN':
          return <Help color="inherit" />;
        case 'INACTIVE':
          return <RemoveCircle color="inherit" />;
        default:
          return '?';
      }
    },
  }),
  columnHelper.accessor((row) => row.description, {
    header: 'Beskrivelse',
    id: 'description',
  }),
  columnHelper.accessor((row) => row.type, {
    header: 'Type',
    id: 'type',
  }),
  columnHelper.accessor((row) => row.comment, {
    header: 'Merknad',
    id: 'comment',
  }),
  columnHelper.display({
    header: 'Actions',
    id: 'actions',
    cell: (info) => (
      <IconButton size="large" color="success" onClick={() => info.table.options.meta?.openEditDialog(info)}>
        <Edit fontSize="inherit" />
      </IconButton>
    ),
  }),
  columnHelper.accessor((row) => row.fromWarehouse, {
    header: 'Fra lager',
    id: 'fromWarehouse',
  }),
  columnHelper.accessor((row) => row.toWarehouse, {
    header: 'Til lager',
    id: 'toWarehouse',
  }),
  columnHelper.accessor((row) => row.identifier, {
    header: 'Identifier plukket',
    id: 'identifier',
  }),
  columnHelper.accessor((row) => row.serialNumber, {
    header: 'Serienummer',
    id: 'serialNumber',
  }),
  columnHelper.accessor((row) => row.itemNumber, {
    header: 'Varenummer',
    id: 'itemNumber',
  }),
  columnHelper.accessor((row) => row.lineItemId, {
    header: 'Linjenr',
    id: 'lineItemId',
  }),
  columnHelper.accessor((row) => row.date, {
    header: 'Periode',
    id: 'dateMonthYear',
    cell: (info) => dayjs(info.getValue()).format('YYYY-MM'),
  }),
];

const initialSortingState: SortingState = [{ id: 'date', desc: true }];

const statusMenuItems = ['OK', 'ERROR', 'INACTIVE', 'UNKNOWN'] as const;

const YearPicker = (props: { value: Dayjs | null, onChange: (year: Dayjs | null) => void }) => {
  const { onChange, value } = props;

  return (
    <Box>
      <DatePicker
        closeOnSelect
        label="År"
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        views={['year']}
        sx={{ width: 100 }}
      />
    </Box>
  );
};

const Filter = (props: GenericTableFilterProps<MrpPickErrorListInner>) => {
  const { table } = props;

  const { data: deviationReasons } = useQuery(ERPQueries.pickErrorDeviationReasonsQuery);

  const mrpColumn = table.getColumn('mrpId');
  const statusColumn = table.getColumn('status');
  const dateColumn = table.getColumn('date');
  // Need to get the string values! (this is number/id!)
  const deviationReasonColumn = table.getColumn('deviationReasonId');

  const reasonOptions = useMemo(() => {
    if (!deviationReasons) return [];
    return deviationReasons.map((reason) => ({ value: reason.id, label: reason.value }));
  }, [deviationReasons]);

  return (
    (
      <Box sx={{ padding: 1 }}>
        <Typography
          gutterBottom
          sx={{
            fontWeight: 'bold',
          }}
        >
          Filter:
        </Typography>
        <Grid2
          container
          spacing={2}
          sx={{
            alignItems: 'center',
          }}
        >
          {statusColumn ? (
            <Grid2>
              <SelectFilter menuItems={statusMenuItems} column={statusColumn} label="Status" />
            </Grid2>
          ) : null}
          {mrpColumn ? (
            <Grid2>
              <AutocompleteFilter column={mrpColumn} label="Masterplan Ordrenummer" />
            </Grid2>
          ) : null}
          {deviationReasonColumn ? (
            <Grid2>
              <SelectFilter menuItems={reasonOptions} column={deviationReasonColumn} label="Avvik" />
            </Grid2>
          ) : null}
          {dateColumn ? (
            <Grid2>
              <DateRangeFilter column={dateColumn} />
            </Grid2>
          ) : null}
        </Grid2>
      </Box>
    )
  );
};

const PickErrorEditDialogContent = (props: DialogRowElementProps<MrpPickErrorListInner>) => {
  const { row, close } = props;

  const queryClient = useQueryClient();
  const { data: deviationReasons } = useQuery(ERPQueries.pickErrorDeviationReasonsQuery);

  const [reason, setReason] = useState<number | ''>(row?.deviationReasonId ?? '');
  const [comment, setComment] = useState<string>(row?.comment ?? '');

  const { mutateAsync: updateMutateAsync, isPending } = useMutation(ERPMutations.updateMrpPickErrorMutation);

  const onSave = async (vars: Omit<DefaultApiApiErpMrpPickErrorPickErrorIdPutRequest, 'authorization'>) => {
    await updateMutateAsync(vars, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ERPQueries.mrpPickErrorsQueryKey, refetchType: 'all' });
        await queryClient.invalidateQueries({ queryKey: ERPQueries.mrpPickErrorsForOrderQueryKey, refetchType: 'all' });
      },
    });
    close();
  };

  if (!row) return null;

  return (
    <Box sx={{ marginTop: 1 }}>
      <Grid2 container spacing={2} alignItems="center">
        <Grid2>
          <FormControl>
            <InputLabel id="select_avvik_label" shrink sx={{ backgroundColor: 'white' }}>Avviksbeskrivelse</InputLabel>
            <Select
              labelId="select_avvik_label"
              id="select_avvik"
              label="Avviksbeskrivelse"
              value={reason}
              onChange={(e) => setReason(e.target.value as number)}
              displayEmpty
              fullWidth
              sx={{ width: 250 }}
            >
              {deviationReasons ? deviationReasons.map((deviationReason) => (
                <MenuItem key={deviationReason.id} value={deviationReason.id}>
                  {deviationReason.value}
                </MenuItem>
              )) : (<MenuItem key="disabled" disabled value="disabled">FEIL: Ingen avviksgrunn funnet</MenuItem>)}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2>
          <TextField
            label="Merknad"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Grid2>
        <Grid2>
          <LoadingButton
            save
            buttonProps={{
              loading: isPending,
              variant: 'contained',
              disabled: isPending,
              onClick: () => onSave({
                pickErrorId: row.id.toString(),
                putMrpPickErrorRequest: {
                  deviationReasonId: reason || undefined,
                  comment,
                },
              }),
              color: 'success',
            }}
          >
            Lagre
          </LoadingButton>
        </Grid2>
        <Grid2>
          <Button variant="contained" color="warning" onClick={() => close()}>Avbryt</Button>
        </Grid2>
      </Grid2>
    </Box>
  );
};

const defaultSearchData: string | undefined = undefined as string | undefined;
const defaultQueryData: GetMrpPickErrorsRequest = { status: undefined };

interface PickErrorStatistic {
  cause: string,
  causeId: number | null,
  monthCountMap: Map<number, { count: number, display?: string }>,
  year: number,
  total: number,
  totalDisplay?: string,
  styling?: { bold: boolean },
  percentage?: number,
}

const statisticColHelper = createColumnHelper<PickErrorStatistic>();

const statisticColumns = [
  statisticColHelper.accessor((row) => row.cause, {
    header: 'Avviksgrunn',
    id: 'cause',
    cell: (info) => (info.row.original.styling?.bold ? (<b>{info.getValue()}</b>) : info.getValue()),
  }),
  ...Array.from({ length: 12 }).map((_, i) => statisticColHelper.display({
    id: i.toString(),
    header: dayjs({ month: i }).locale('nb').format('MMM'),
    cell: (info) => {
      const monthMap = info.row.original.monthCountMap.get(i);
      return monthMap?.display ?? monthMap?.count ?? 0;
    },
  })),
  statisticColHelper.accessor((row) => row.total, {
    header: 'Sum',
    id: 'sum',
    cell: (info) => {
      if (info.row.original.totalDisplay) return info.row.original.totalDisplay;
      return info.getValue();
    },
  }),
  statisticColHelper.accessor((row) => row.percentage, {
    header: 'Andel',
    id: 'percentage',
    cell: (info) => (info.getValue() ? `${info.getValue()?.toFixed(1).toString()}%` : ''),
  }),
];

const defaultOrderStatData: DateCommand = { startTime: undefined, endTime: undefined };

const ERPMRPPickErrors = () => {
  const [yearDate, setYearDate] = useState<Dayjs | null>(dayjs());
  const [tab, setTab] = useState<string>('1');
  const [search, setSearch] = useState<string>('');
  const [status, setStatus] = useState<GetMrpPickErrorsRequestStatusEnum | 'ALL'>('ALL');

  const { queryData: query, refetch: statusRefetch, search: statusSearch } = useSearchQuery(ERPQueries.getMRPPickErrorsQuery, defaultQueryData);
  const { isLoading, data, refetch } = query;
  const { queryData: singleData, refetch: searchRefetch } = useSearchQuery(ERPQueries.getMRPPickErrorsForOrderQuery, defaultSearchData);

  const {
    queryData: orderStatsQuery, refetch: orderStatisticsRefetch,
  } = useSearchQuery(ERPQueries.getMrpOrderStatisticsQuery, defaultOrderStatData);
  const { data: orderStatistics } = orderStatsQuery;

  const { data: deviationReasons } = useQuery(ERPQueries.pickErrorDeviationReasonsQuery);

  const dynamicColumns = useMemo(() => [
    columnHelper.accessor((row) => row.deviationReasonId, {
      header: 'Avviksgrunn',
      id: 'deviationReasonId',
      cell: (info) => {
        const reason = deviationReasons?.find((r) => r.id === info.getValue());
        return reason?.value ?? null;
      },
    }),
  ], [deviationReasons]);

  const allColumns = useMemo(() => columns.toSpliced(6, 0, ...dynamicColumns), [dynamicColumns]);

  const tableData = useMemo(() => (search ? singleData.data ?? [] : data ?? []), [data, search, singleData.data]);

  const statistics = useMemo<PickErrorStatistic[]>(() => {
    const monthArray = Array.from({ length: 12 });
    const year = yearDate?.year() ?? dayjs().year();
    // TODO All orders per month is also needed (erpSyncOrder endpoint)
    // For now: do per month (this year, fix search/picker for other years later)
    // All with reason -> add to that reason.
    // Without -> "ukjent feil" or something like that.
    // First is causeId, second is month
    const deviationReasonMap = new Map<number, string>();
    deviationReasons?.forEach((deviationReason) => deviationReasonMap.set(deviationReason.id, deviationReason.value));

    const sumAvvik: PickErrorStatistic = {
      monthCountMap: new Map<number, { count: number }>(monthArray.map((_, i) => [i, { count: 0 }])),
      year,
      cause: 'Sum Avvik',
      causeId: -1,
      total: 0,
      styling: { bold: true },
    };
    const sumSystem: PickErrorStatistic = {
      monthCountMap: new Map<number, { count: number }>(monthArray.map((_, i) => [i, { count: 0 }])),
      year,
      cause: 'Sum Systemfeil',
      causeId: -2,
      total: 0,
      styling: { bold: true },
    };
    const sumTotal: PickErrorStatistic = {
      monthCountMap: new Map<number, { count: number }>(monthArray.map((_, i) => [i, { count: 0 }])),
      year,
      cause: 'Sum feil/avvik',
      causeId: -3,
      total: 0,
      styling: { bold: true },
    };
    const sumAC: PickErrorStatistic = {
      monthCountMap: new Map<number, { count: number }>(monthArray.map((_, i) => [i, { count: 0 }])),
      year,
      cause: 'Sum AC fakturert',
      causeId: -4,
      total: 0,
    };

    const sumACT: PickErrorStatistic = {
      monthCountMap: new Map<number, { count: number }>(monthArray.map((_, i) => [i, { count: 0 }])),
      year,
      cause: 'Sum ACT fakturert',
      causeId: -5,
      total: 0,
    };

    const sumOrders: PickErrorStatistic = {
      monthCountMap: new Map<number, { count: number }>(monthArray.map((_, i) => [i, { count: 0 }])),
      year,
      cause: 'Sum ordrer fakturert',
      causeId: -6,
      total: 0,
      styling: { bold: true },
    };

    const sumWithoutFail: PickErrorStatistic = {
      monthCountMap: new Map<number, { count: number }>(monthArray.map((_, i) => [i, { count: 0 }])),
      year,
      cause: 'Sum som går igjennom uten feil',
      causeId: -7,
      total: 0,
    };

    const sumWithoutFailPercentage: PickErrorStatistic = {
      monthCountMap: new Map<number, { count: number }>(monthArray.map((_, i) => [i, { count: 0 }])),
      year,
      cause: 'Andel som går igjennom uten feil',
      causeId: -8,
      total: 0,
    };

    const statisticMap = new Map<number | null, PickErrorStatistic>();
    deviationReasons?.forEach((reason) => {
      const monthMap = new Map<number, { count: number }>();
      monthArray.forEach((_, i) => monthMap.set(i, {
        count: 0,
      }));
      statisticMap.set(reason.id, {
        cause: reason.value, causeId: reason.id, monthCountMap: monthMap, year, total: 0,
      });
    });
    const nullMonthMap = new Map<number, { count: number }>();
    monthArray.forEach((_, i) => nullMonthMap.set(i, {
      count: 0,
    }));
    statisticMap.set(null, {
      cause: 'Ukjent', causeId: null, monthCountMap: nullMonthMap, year, total: 0,
    });

    for (const orderStat of orderStatistics ?? []) {
      // TODO invoicedate or created date? or match with "data" to find that date!?
      const date = DateUtils.getDayjs(orderStat.mrpInvoicedDate ?? orderStat.createdDate);
      const month = date?.month();
      // eslint-disable-next-line no-continue
      if (!date || date?.year() !== year || month === undefined) continue;

      if (orderStat.isACT) {
        sumACT.total += 1;
        const sumACTMonthCount = sumACT.monthCountMap.get(month);
        if (sumACTMonthCount) sumACTMonthCount.count += 1;
      } else {
        sumAC.total += 1;
        const sumACMonthCount = sumAC.monthCountMap.get(month);
        if (sumACMonthCount) sumACMonthCount.count += 1;
      }

      sumOrders.total += 1;
      const sumOrderMonthCount = sumOrders.monthCountMap.get(month);
      if (sumOrderMonthCount) sumOrderMonthCount.count += 1;
    }

    if (!data?.length) return [];

    // TODO Filter data so only 1 per is counted
    // If at least one avvik -> avvik
    // If no avvik but at least one systemfeil -> systemfeil
    // Else -> ukjent

    const grouped = Object.groupBy(data, (row) => row.mrpId);

    for (const row of Object.entries(grouped)) {
      const [, rows] = row;
      // skip partnerprog errors for statistics
      // eslint-disable-next-line no-continue
      if (rows?.some((r) => r.orderType === 'PARTNERPROG_INTERNAL' || r.orderType === 'PARTNERPROG')) continue;

      const firstReasonId = rows?.find((r) => !!r.deviationReasonId)?.deviationReasonId;
      const firstReason = firstReasonId ? deviationReasonMap.get(firstReasonId)?.toLowerCase().trim() : undefined;
      const firstRow = rows?.find((r) => !!r.date); // TODO sort by date? (asc/desc?)

      // eslint-disable-next-line no-continue
      if (!firstRow) continue;

      const orderStat = orderStatistics?.find((o) => o.mrpId === firstRow.mrpId);

      let date = DateUtils.getUTCDayjs(firstRow.date);
      let month = DateUtils.getUTCDayjs(firstRow.date)?.month();
      if (orderStat && (orderStat.mrpInvoicedDate || orderStat.createdDate)) {
        date = DateUtils.getUTCDayjs(orderStat.mrpInvoicedDate ?? orderStat.createdDate);
        month = date?.month();
      }
      // eslint-disable-next-line no-continue
      if (!date || date?.year() !== year || month === undefined) continue;

      const statistic = statisticMap.get(firstRow.deviationReasonId);
      // eslint-disable-next-line no-continue
      if (!statistic) continue;

      const monthCount = statistic.monthCountMap.get(month);
      if (monthCount) monthCount.count += 1;

      statistic.total += 1;
      sumTotal.total += 1;
      const sumTotalMonthCount = sumTotal.monthCountMap.get(month);
      if (sumTotalMonthCount) sumTotalMonthCount.count += 1;

      if (firstReason) {
        if (firstReason.startsWith('systemfeil')) {
          sumSystem.total += 1;
          const sumSystemMonthCount = sumSystem.monthCountMap.get(month);
          if (sumSystemMonthCount) sumSystemMonthCount.count += 1;
        } else if (firstReason.startsWith('avvik')) {
          sumAvvik.total += 1;
          const sumAvvikMonthCount = sumAvvik.monthCountMap.get(month);
          if (sumAvvikMonthCount) sumAvvikMonthCount.count += 1;
        }
      }
    }

    const statisticMapArray = statisticMap.values().toArray();
    for (const month of monthArray.map((_, i) => i)) {
      const ordersMonth = sumOrders.monthCountMap.get(month);
      const ordersMonthCount = ordersMonth?.count ?? 0;
      const statMonth = sumTotal.monthCountMap.get(month);
      const statMonthCount = statMonth?.count ?? 0;

      const withoutFailMonth = sumWithoutFail.monthCountMap.get(month);
      const withoutFailPercentageMonth = sumWithoutFailPercentage.monthCountMap.get(month);
      if (withoutFailMonth && ordersMonthCount >= statMonthCount) {
        withoutFailMonth.count = ordersMonthCount - statMonthCount;
        sumWithoutFail.total += withoutFailMonth.count;
        if (withoutFailPercentageMonth && ordersMonthCount > 0) {
          withoutFailPercentageMonth.count = 0;
          withoutFailPercentageMonth.display = `${((withoutFailMonth.count / ordersMonthCount) * 100).toFixed(1)}%`;
        } else if (withoutFailPercentageMonth) {
          withoutFailPercentageMonth.display = '';
        }
      }
      // subtract invoiced orders with total fails each month (+ total)
      // then calc percentage against total orders each month (+ total)
    }

    sumWithoutFailPercentage.totalDisplay = sumOrders.total > 0 ? `${((sumWithoutFail.total / sumOrders.total) * 100).toFixed(1)}%` : '';

    const sumOrdersPositive = sumOrders.total > 0;

    const mappedStatistics = statisticMapArray.map((s) => ({
      ...s,
      percentage: sumOrdersPositive ? ((s.total / sumOrders.total) * 100) : undefined,
    }));

    return [...mappedStatistics, {
      ...sumAvvik,
      percentage: sumOrdersPositive ? ((sumAvvik.total / sumOrders.total) * 100) : undefined,
    }, {
      ...sumSystem,
      percentage: sumOrdersPositive ? ((sumSystem.total / sumOrders.total) * 100) : undefined,
    }, {
      ...sumTotal,
      percentage: sumOrdersPositive ? ((sumTotal.total / sumOrders.total) * 100) : undefined,
    }, sumAC, sumACT, sumOrders, sumWithoutFail, sumWithoutFailPercentage];
  }, [data, deviationReasons, orderStatistics, yearDate]);

  return (
    <Box>
      <TabContext value={tab}>
        <TabList onChange={(_, val) => setTab(val)} aria-label="Plukkfeil tabs">
          <Tab
            label="Oversikt"
            value="1"
            onClick={() => {
              statusRefetch({ status: status === 'ALL' ? undefined : status });
            }}
          />
          <Tab
            label="Statistikk"
            value="2"
            onClick={() => {
              statusRefetch({ status: undefined });
            }}
          />
        </TabList>
        <TabPanel value="1">
          <Box>
            <MonitorPageTitle title="Masterplan Plukk Avvik / Feil - Oversikt" />
            <Grid2 container spacing={2} alignItems="center">
              <Grid2>
                <TextField
                  label="Søk med referanse"
                  variant="outlined"
                  title="Søk med referanse"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Grid2>
              <Grid2>
                <LoadingButton buttonProps={{
                  loading: singleData.isLoading,
                  variant: 'contained',
                  disabled: !search,
                  onClick: () => searchRefetch(search),
                  color: 'success',
                }}
                >
                  Søk
                </LoadingButton>
              </Grid2>
              <Grid2>
                <Button
                  onClick={() => {
                    setSearch('');
                    searchRefetch(undefined);
                  }}
                  variant="contained"
                  disabled={!search}
                  color="warning"
                >
                  Reset søk
                </Button>
              </Grid2>
            </Grid2>
            <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
            <Grid2 container spacing={2} alignItems="center" sx={{ marginBottom: 4 }}>
              <Grid2>
                <YearPicker value={yearDate} onChange={setYearDate} />
              </Grid2>
              <Grid2>
                <FormControl>
                  <InputLabel id="select_status_label" shrink sx={{ backgroundColor: 'white' }}>
                    Status
                  </InputLabel>
                  <Select
                    labelId="select_status_label"
                    id="select_status"
                    displayEmpty
                    label="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value as GetMrpPickErrorsRequestStatusEnum | 'ALL')}
                    fullWidth
                    sx={{ width: 100 }}
                  >
                    <MenuItem key="ALL" value="ALL">Alle</MenuItem>
                    {Object.values(GetMrpPickErrorsRequestStatusEnum).map((v) => (<MenuItem key={v} value={v}>{v}</MenuItem>))}
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2>
                {status !== (statusSearch.status ?? 'ALL') ? (
                  <LoadingButton buttonProps={{
                    loading: isLoading,
                    variant: 'contained',
                    disabled: isLoading,
                    onClick: () => {
                      if (yearDate) {
                        orderStatisticsRefetch({
                          startTime: yearDate.startOf('year').toISOString(),
                          endTime: yearDate.endOf('year').toISOString(),
                        });
                      }
                      statusRefetch({ status: status === 'ALL' ? undefined : status });
                    },
                    color: 'success',
                  }}
                  >
                    Søk
                  </LoadingButton>
                ) : (
                  <LoadingButton buttonProps={{
                    loading: isLoading,
                    variant: 'contained',
                    disabled: isLoading,
                    onClick: () => refetch(),
                    color: 'warning',
                  }}
                  >
                    Hent data på nytt
                  </LoadingButton>
                )}
              </Grid2>
            </Grid2>
            <GenericTable
              id="mrp-pick-errors"
              pagination
              columns={allColumns}
              data={tableData}
              initialSortingState={initialSortingState}
              Filter={Filter}
              editDialogProps={{
                Content: PickErrorEditDialogContent,
                title: 'Rediger plukk-feil',
                maxWidth: 'sm',
              }}
            />
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box>
            <MonitorPageTitle title={`Masterplan Plukk Avvik / Feil - Statistikk (${yearDate?.year()})`} />
            <GenericTable
              id="mrp-pick-errors-statistics"
              columns={statisticColumns}
              data={statistics}
            />
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default ERPMRPPickErrors;
