import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Box } from '@mui/material';
import type { DialogRowElementProps } from '../../../table/GenericTable.js';
import type { GetInstallItemUsageResponseInner } from '../../../../../generated/activityhelper/api.js';
import { InstallItemUsageMutations } from '../../../../mutations/index.js';
import InstallItemUsageAddEditForm from './InstallItemUsageAddEditForm.js';
import { InstallItemUsageQueries } from '../../../../queries/index.js';

const InstallItemUsageEditContent = (props: DialogRowElementProps<GetInstallItemUsageResponseInner>) => {
  const { row, close } = props;

  const queryClient = useQueryClient();
  const updateItemUsageMutation = useMutation({
    ...InstallItemUsageMutations.updateItemUsageMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(InstallItemUsageQueries.installItemUsagesQuery);
      close();
    },
  });

  if (!row) return null;
  return (
    <Box>
      <InstallItemUsageAddEditForm
        type="edit"
        defaultData={row}
        onSubmit={(data) => updateItemUsageMutation.mutateAsync({
          itemNumber: row.itemNumber,
          putInstallItemUsageRequest: data,
        })}
      />
    </Box>
  );
};

export default InstallItemUsageEditContent;
