import React from 'react';
import { useForm } from '@tanstack/react-form';
import {
  Box, Checkbox, FormControlLabel, Grid2, IconButton, Stack, TextField, Tooltip,
  Typography,
} from '@mui/material';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import { Clear } from '@mui/icons-material';
import type { PostInstallItemUsageAlternativeRequest } from '../../../../../generated/activityhelper/api.js';
import LoadingButton from '../../../common/buttons/LoadingButton.js';

type ItemUsageAlternative = PostInstallItemUsageAlternativeRequest;

type InstallItemUsageAlternativeAddEditFormProps<T extends 'edit' | 'add'> = {
  defaultData?: ItemUsageAlternative;
  onSubmit: (data: ItemUsageAlternative) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  type: T;
};

const numberNullSchema = z.number().positive().nullable();

const InstallItemUsageAlternativeAddEditForm = <T extends 'edit' | 'add', >(props: InstallItemUsageAlternativeAddEditFormProps<T>) => {
  const { defaultData, onSubmit } = props;
  const form = useForm<ItemUsageAlternative>({
    defaultValues: defaultData ? {
      ...defaultData,
      useSalesOrder: defaultData.useSalesOrder ?? true,
    } : {
      useSalesOrder: true,
      questionId: undefined as unknown as number,
    },
    onSubmit: ({ value }) => {
      onSubmit({
        ...value,
        itemNumber: value.itemNumber ? value.itemNumber : null,
        answer: value.answer ? value.answer : null,
        quantityFactor: value.quantityFactor ? value.quantityFactor : null,
        questionName: value.questionName ? value.questionName : null,
        flatAddition: value.flatAddition ? value.flatAddition : null,
      });
    },
  });

  return (
    <Box sx={{ marginTop: 1 }}>
      <form onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      >
        <Grid2 container spacing={1} direction="column">
          <Grid2>
            <Typography>
              Den ser etter dette ved utrekning og bruker det som dukker opp først:
            </Typography>
            <ol>
              <li>
                match på alternativ og produktnr er tomt = legg ikke til!
              </li>
              <li>
                den følger så:
                {' '}
                <code>antall bestemt via &quot;hovedforbruk&quot; * antallsfaktor (om satt) + flatt tillegg (om satt)</code>
              </li>
            </ol>
          </Grid2>
          <Grid2>
            <form.Field
              name="itemNumber"
              validatorAdapter={zodValidator()}
              validators={{ onChange: z.optional(z.string().min(1).nullable()) }}
            >
              {(field) => (
                <Stack direction="row">
                  <Tooltip title="Er dette feltet tomt vil den hoppe over forbruksvaren ved treff på spørsmål/svar">
                    <TextField
                      id={field.name}
                      label="Produktnr"
                      value={field.state.value ?? ''}
                      onChange={(e) => field.handleChange(e.target.value)}
                      error={!!field.state.meta.errors.length}
                      helperText={field.state.meta.errors.join(', ')}
                    />
                  </Tooltip>
                  <Tooltip title="Fjern"><IconButton onClick={() => field.handleChange(null)}><Clear /></IconButton></Tooltip>
                </Stack>
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field
              name="questionId"
              validatorAdapter={zodValidator()}
              validators={{ onChange: z.number().positive() }}
            >
              {(field) => (
                <TextField
                  required
                  type="number"
                  label="Spørsmåls ID"
                  value={field.state.value ?? ''}
                  onChange={(e) => field.handleChange(Number(e.target.value))}
                  error={!!field.state.meta.errors.length}
                  helperText={field.state.meta.errors.join(', ')}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field
              name="questionName"
              validatorAdapter={zodValidator()}
              validators={{ onChange: z.optional(z.string().min(1).nullable()) }}
            >
              {(field) => (
                <Stack direction="row">
                  <TextField
                    // disabled={type === 'edit'}
                    label="Spørsmålsnavn (blir brukt over ID)"
                    value={field.state.value ?? ''}
                    onChange={(e) => field.handleChange(e.target.value)}
                    error={!!field.state.meta.errors.length}
                    helperText={field.state.meta.errors.join(', ')}
                    sx={{ width: 300 }}
                  />
                  <Tooltip title="Fjern"><IconButton onClick={() => field.handleChange(null)}><Clear /></IconButton></Tooltip>
                </Stack>
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field
              name="useSalesOrder"
              validatorAdapter={zodValidator()}
              validators={{ onChange: z.optional(z.boolean().nullable()) }}
            >
              {(field) => (
                <FormControlLabel
                  label="Bruk CPPro salgsordre (for svar på spørmål)?"
                  control={(
                    <Checkbox
                      checked={field.state.value ?? true}
                      onChange={(_, checked) => field.handleChange(checked)}
                    />
                )}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field
              name="answer"
              validatorAdapter={zodValidator()}
              validators={{ onChange: z.string().min(1) }}
            >
              {(field) => (
                <TextField
                  required
                  // disabled={type === 'edit'}
                  label="Svaret den skal treffe"
                  value={field.state.value ?? ''}
                  onChange={(e) => field.handleChange(e.target.value)}
                  error={!!field.state.meta.errors.length}
                  helperText={field.state.meta.errors.join(', ')}
                  sx={{ width: 300 }}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field
              name="quantityFactor"
              validatorAdapter={zodValidator()}
              validators={{
                onChange: z.optional(numberNullSchema),
              }}
            >
              {(field) => (
                <Stack direction="row">
                  <TextField
                    type="number"
                    label="Antallsfaktor"
                    value={field.state.value ?? ''}
                    onChange={(e) => field.handleChange(Number(e.target.value))}
                    error={!!field.state.meta.errors.length}
                    helperText={field.state.meta.errors.join(', ')}
                  />
                  <Tooltip title="Fjern"><IconButton onClick={() => field.handleChange(null)}><Clear /></IconButton></Tooltip>
                </Stack>
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field
              name="flatAddition"
              validatorAdapter={zodValidator()}
              validators={{ onChange: z.optional(numberNullSchema) }}
            >
              {(field) => (
                <Stack direction="row">
                  <Tooltip title="Denne legg til et flatt tillegg i tillegg til antall meter (eks. 3 meter brukt + 0.5 flatt tillegg = 3.5 meter). Alternativ overskriver!">
                    <TextField
                      type="number"
                      label="Flatt tillegg"
                      value={field.state.value ?? ''}
                      onChange={(e) => field.handleChange(Number(e.target.value))}
                      error={!!field.state.meta.errors.length}
                      helperText={field.state.meta.errors.join(', ')}
                    />
                  </Tooltip>
                  <Tooltip title="Fjern"><IconButton onClick={() => field.handleChange(null)}><Clear /></IconButton></Tooltip>
                </Stack>
              )}
            </form.Field>
          </Grid2>
          <Grid2 container sx={{ justifyContent: 'flex-end' }}>
            <form.Subscribe selector={((state) => [state.canSubmit, state.isSubmitting])}>
              {([canSubmit, isSubmitting]) => (
                <LoadingButton
                  save
                  buttonProps={{
                    variant: 'contained',
                    type: 'submit',
                    loading: isSubmitting,
                    disabled: !canSubmit || isSubmitting,
                    color: 'success',
                  }}
                >
                  Lagre
                </LoadingButton>
              )}
            </form.Subscribe>
          </Grid2>
        </Grid2>
      </form>
    </Box>
  );
};

export default InstallItemUsageAlternativeAddEditForm;
