import React from 'react';
import { Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { DialogRowElementProps } from '../../../table/GenericTable.js';
import type { GetInstallItemUsageResponseInnerAlternativesInner } from '../../../../../generated/activityhelper/api.js';
import { InstallItemUsageMutations } from '../../../../mutations/index.js';
import { InstallItemUsageQueries } from '../../../../queries/index.js';
import InstallItemUsageAlternativeAddEditForm from './InstallItemUsageAlternativeAddEditForm.js';

const InstallItemUsageAlternativeEditContent = (props: DialogRowElementProps<GetInstallItemUsageResponseInnerAlternativesInner>) => {
  const { row, close } = props;
  const queryClient = useQueryClient();
  const updateAlternativeMutation = useMutation({
    ...InstallItemUsageMutations.updateItemUsageAlternativeMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(InstallItemUsageQueries.installItemUsagesQuery);
      close('submit');
    },
  });

  if (!row) return null;

  return (
    <Box>
      <InstallItemUsageAlternativeAddEditForm
        type="edit"
        defaultData={row}
        onSubmit={(data) => updateAlternativeMutation.mutateAsync({
          alternativeId: row.id.toString(),
          itemUsageId: row.itemUsageId.toString(),
          putInstallItemUsageAlternativeRequest: data,
        })}
      />
    </Box>
  );
};

export default InstallItemUsageAlternativeEditContent;
