import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import type {
  CellContext,
  ColumnDef, ColumnFiltersState, FilterFn, FilterFnOption, RowData, SortingState, Table,
  VisibilityState,
} from '@tanstack/react-table';
import {
  flexRender, getCoreRowModel, getExpandedRowModel, getFacetedMinMaxValues,
  getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel,
  getGroupedRowModel, getPaginationRowModel, getSortedRowModel, useReactTable,
} from '@tanstack/react-table';
import type { DialogProps } from '@mui/material';
import {
  Table as MuiTable,
  Paper,
  TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button, Box,
} from '@mui/material';
import TablePaginationActions from './TablePaginationActions.js';
import { isWithinDateRangeFilter } from './DateRangeFilter.js';
import './GenericTable.css';
import ConfirmDialog from '../common/dialogs/ConfirmDialog.js';
import DialogCloseButton from '../common/dialogs/DialogCloseButton.js';
import nullEqualsFilter from './filters/nullEqualsFilter.js';

// Defined custom filters to make typescript happy ;)
declare module '@tanstack/table-core' {
  interface FilterFns {
    isWithinRange: FilterFn<unknown>,
    nullEquals: FilterFn<unknown>,
  }
  interface TableMeta<TData extends RowData> {
    openDataDialog(data: unknown): void
    openRowDialog(ctx: CellContext<TData, unknown>): void
    openDeleteDialog(ctx: CellContext<TData, unknown>): void
    openEditDialog(ctx: CellContext<TData, unknown>): void
    openAddDialog(ctx: CellContext<TData, unknown>): void
    custom?: unknown,
  }
}

type DialogCloseActionType = 'submit' | 'close';

type DialogType = 'data' | 'row' | 'delete' | 'edit' | 'add';

export type DialogRowElementProps<TRow> = {
  row: TRow | undefined,
  close: (action?: DialogCloseActionType) => void,
  type: DialogType,
};

export type DialogRowTitleProps<T> = {
  row: T | undefined,
};

export type DialogDataElementProps<TData> = {
  data: TData | undefined,
  close: (action?: DialogCloseActionType) => void,
  type: DialogType,
};

export type DialogDataTitleProps<T> = {
  data: T | undefined,
};

export type GenericTableFilterProps<T> = {
  table: Table<T>,
  tableId?: string,
};

type GenericTableProps<T, TDialog = unknown, TCustomMeta = unknown> = {
  // Workaround for https://github.com/TanStack/table/issues/4382
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[],
  data: T[],
  pagination?: boolean,
  defaultPageSize?: number,
  Filter?: React.FC<GenericTableFilterProps<T>> | null,
  initialSortingState?: SortingState,
  initialColumnFiltersState?: ColumnFiltersState,
  id?: string,
  /**
   * Enable a dialog which is provided the provided data when opened (ex. to show detailed info of a column)
   */
  dataDialogProps?: {
    title: string | React.FC<DialogDataTitleProps<TDialog>>,
    Content: React.FC<DialogDataElementProps<TDialog>>,
    Actions?: React.FC<DialogDataElementProps<TDialog>>,
    maxWidth?: DialogProps['maxWidth'],
  },
  /**
   * Used to update dialog data when table data is changed (ex. when a row is deleted/updated)
   * @param row
   * @returns
   */
  keyExtractor?: (row: T) => T[keyof T],
  /**
   * Enable a dialog which is provided row data when opened
   */
  rowDialogProps?: {
    title: string | React.FC<DialogRowTitleProps<T>>,
    Content: React.FC<DialogRowElementProps<T>>,
    Actions?: React.FC<DialogRowElementProps<T>>,
    maxWidth?: DialogProps['maxWidth'],
    initialRowFinder?: (row: T) => boolean,
    rowClickOpen?: boolean,
    onDialogClose?: (action?: DialogCloseActionType) => void,
  },
  editDialogProps?: {
    title: string | React.FC<DialogRowTitleProps<T>>,
    Content: React.FC<DialogRowElementProps<T>>,
    maxWidth?: DialogProps['maxWidth'],
    Actions?: React.FC<DialogRowElementProps<T>>,
    refreshRowFinder?: (row: T) => boolean,
    onEdit?: (row: T | undefined, close: () => void) => void,
    rowClickOpen?: boolean,
    onDialogClose?: (action?: DialogCloseActionType) => void,
  },
  addDialogProps?: {
    title: string | React.FC<DialogRowTitleProps<T>>,
    Content: React.FC<DialogRowElementProps<T>>,
    maxWidth?: DialogProps['maxWidth'],
    Actions?: React.FC<DialogRowElementProps<T>>,
    refreshRowFinder?: (row: T) => boolean,
    onAdd?: (row: T | undefined, close: () => void) => void,
    rowClickOpen?: boolean,
    onDialogClose?: (action?: DialogCloseActionType) => void,
  },
  deleteDialogProps?: {
    onDelete: (row: T | undefined, close: () => void) => void,
    title?: string,
    description?: string,
    rowClickOpen?: boolean,
  },
  initialColumnVisibility?: VisibilityState,
  initialGlobalFilter?: unknown,
  /**
   * Defaults to "includeString"
   */
  globalFilterFn?: FilterFnOption<T>,
  customMeta?: TCustomMeta,
};

export const GenericTable = <T, TDialog = unknown>(props: GenericTableProps<T, TDialog>) => {
  const {
    columns, data, pagination, defaultPageSize, Filter, initialSortingState, initialColumnFiltersState,
    id, dataDialogProps, rowDialogProps, editDialogProps, deleteDialogProps, keyExtractor,
    initialColumnVisibility, globalFilterFn, initialGlobalFilter, customMeta, addDialogProps,
  } = props;

  // Dialogs
  const [rowDialogOpen, setRowDialogOpen] = useState(false);
  const [rowDialogData, setRowDialogData] = useState<T | undefined>(undefined);
  const [dataDialogOpen, setDataDialogOpen] = useState(false);
  const [dataDialogData, setDataDialogData] = useState<TDialog | undefined>(undefined);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editDialogData, setEditDialogData] = useState<T | undefined>(undefined);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogData, setDeleteDialogData] = useState<T | undefined>(undefined);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [addDialogData, setAddDialogData] = useState<T | undefined>(undefined);

  useEffect(() => {
    if (rowDialogProps?.initialRowFinder) {
      const row = data.find(rowDialogProps.initialRowFinder);
      if (row) {
        setRowDialogData(row);
        setRowDialogOpen(true);
      }
    }
  }, [data, rowDialogProps?.initialRowFinder]);

  useEffect(() => {
    if (keyExtractor) {
      setRowDialogData((prev) => {
        if (!prev) return prev;
        const prevKey = keyExtractor(prev);
        const row = data.find((r) => keyExtractor(r) === prevKey);
        return row;
      });
      setEditDialogData((prev) => {
        if (!prev) return prev;
        const prevKey = keyExtractor(prev);
        const row = data.find((r) => keyExtractor(r) === prevKey);
        return row;
      });
    }
  }, [data, keyExtractor]);

  // Data
  const openDataDialog = (params: TDialog) => {
    setDataDialogData(params);
    setDataDialogOpen(true);
  };
  const closeDataDialog = () => setDataDialogOpen(false);

  // Row / info
  const openRowDialog = useCallback((ctx: Pick<CellContext<T, TDialog>, 'row'>) => {
    const row = ctx.row.original;
    setRowDialogData(row);
    setRowDialogOpen(true);
  }, []);
  const closeRowDialog = useCallback((action?: DialogCloseActionType) => {
    setRowDialogOpen(false);
    if (rowDialogProps?.onDialogClose) rowDialogProps.onDialogClose(action);
  }, [rowDialogProps]);

  // Edit
  const openEditDialog = useCallback((ctx: Pick<CellContext<T, TDialog>, 'row'>) => {
    const row = ctx.row.original;
    setEditDialogData(row);
    setEditDialogOpen(true);
  }, []);
  const closeEditDialog = useCallback((action?: DialogCloseActionType) => {
    setEditDialogOpen(false);
    if (editDialogProps?.onDialogClose) editDialogProps.onDialogClose(action);
  }, [editDialogProps]);

  // Delete
  const openDeleteDialog = useCallback((ctx: Pick<CellContext<T, TDialog>, 'row'>) => {
    const row = ctx.row.original;
    setDeleteDialogData(row);
    setDeleteDialogOpen(true);
  }, []);
  const closeDeleteDialog = () => setDeleteDialogOpen(false);

  // Add
  const openAddDialog = useCallback((ctx: Pick<CellContext<T, TDialog>, 'row'>) => {
    const row = ctx.row.original;
    setAddDialogData(row);
    setAddDialogOpen(true);
  }, []);
  const closeAddDialog = useCallback((action?: DialogCloseActionType) => {
    setAddDialogOpen(false);
    if (addDialogProps?.onDialogClose) addDialogProps.onDialogClose(action);
  }, [addDialogProps]);

  const onRowClick = useMemo(() => {
    if (editDialogProps?.rowClickOpen) return openEditDialog;
    if (deleteDialogProps?.rowClickOpen) return openDeleteDialog;
    if (rowDialogProps?.rowClickOpen) return openRowDialog;
    if (addDialogProps?.rowClickOpen) return openAddDialog;
    return undefined;
  }, [deleteDialogProps?.rowClickOpen,
    editDialogProps?.rowClickOpen,
    rowDialogProps?.rowClickOpen,
    addDialogProps?.rowClickOpen,
    openDeleteDialog,
    openEditDialog,
    openRowDialog,
    openAddDialog,
  ]);

  // Table
  const [sorting, setSorting] = useState<SortingState>(initialSortingState ?? []);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(initialColumnFiltersState ?? []);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(initialColumnVisibility ?? {});
  const [globalFilter, setGlobalFilter] = useState<unknown>(initialGlobalFilter ?? []);

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: globalFilterFn ?? 'includesString',
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: pagination ? getPaginationRowModel() : undefined,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    initialState: {
      pagination: {
        pageSize: defaultPageSize ?? 50,
      },
    },
    meta: {
      openDataDialog,
      openRowDialog,
      openDeleteDialog,
      openEditDialog,
      openAddDialog,
      custom: customMeta,
    },
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      globalFilter,
    },
    filterFns: {
      isWithinRange: isWithinDateRangeFilter,
      nullEquals: nullEqualsFilter,
    },
  });

  return (
    <Box component={Paper}>
      {Filter ? (
        <Filter table={table} tableId={id} />
      ) : null}
      <TableContainer sx={{ maxHeight: '70vh' }}>
        <MuiTable stickyHeader id={id}>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <Box>
                          <TableSortLabel
                            active={!!header.column.getIsSorted()}
                            direction={!header.column.getIsSorted() ? undefined : header.column.getIsSorted() as 'asc' | 'desc'}
                            onClick={header.column.getToggleSortingHandler()}
                            disabled={!header.column.getCanSort()}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </TableSortLabel>
                        </Box>
                        {/* {header.column.getCanFilter() ? (
                            <Filter column={header.column} table={table} />
                          ) : null} */}
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                hover={!!onRowClick}
                onClick={onRowClick ? () => onRowClick({ row }) : undefined}
                sx={{ cursor: onRowClick ? 'pointer' : 'default' }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {pagination ? (
        <TablePagination
          component="div"
          ActionsComponent={TablePaginationActions}
          count={table.getFilteredRowModel().rows.length}
          page={table.getState().pagination.pageIndex}
          rowsPerPage={table.getState().pagination.pageSize}
          rowsPerPageOptions={[50, 100, 150, 200, 250]}
          onPageChange={(_, page) => table.setPageIndex(page)}
          onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
        />
      ) : null}
      {dataDialogProps ? (
        <Dialog open={dataDialogOpen} onClose={closeDataDialog} maxWidth={dataDialogProps.maxWidth || 'md'} fullWidth>
          <DialogTitle>{typeof dataDialogProps.title === 'string' ? dataDialogProps.title : <dataDialogProps.title data={dataDialogData} /> }</DialogTitle>
          <DialogCloseButton handleClose={closeDataDialog} />
          <DialogContent>
            <dataDialogProps.Content data={dataDialogData} close={closeDataDialog} type="data" />
          </DialogContent>
          {dataDialogProps.Actions ? (
            <DialogActions>
              <dataDialogProps.Actions data={dataDialogData} close={closeDataDialog} type="data" />
            </DialogActions>
          ) : null}
        </Dialog>
      ) : null}
      {rowDialogProps ? (
        <Dialog open={rowDialogOpen} onClose={() => closeRowDialog('close')} maxWidth={rowDialogProps.maxWidth || 'md'} fullWidth>
          <DialogTitle>{typeof rowDialogProps.title === 'string' ? rowDialogProps.title : <rowDialogProps.title row={rowDialogData} /> }</DialogTitle>
          <DialogCloseButton handleClose={closeRowDialog} />
          <DialogContent>
            <rowDialogProps.Content close={closeRowDialog} row={rowDialogData} type="row" />
          </DialogContent>
          {rowDialogProps.Actions ? (
            <DialogActions>
              <rowDialogProps.Actions close={closeRowDialog} row={rowDialogData} type="row" />
            </DialogActions>
          ) : null}
        </Dialog>
      ) : null}
      {addDialogProps ? (
        <Dialog open={addDialogOpen} onClose={() => closeAddDialog('close')} maxWidth={addDialogProps.maxWidth || 'md'} fullWidth>
          <DialogTitle>{typeof addDialogProps.title === 'string' ? addDialogProps.title : <addDialogProps.title row={addDialogData} />}</DialogTitle>
          <DialogCloseButton handleClose={closeAddDialog} />
          <DialogContent>
            <addDialogProps.Content row={addDialogData} close={closeAddDialog} type="add" />
          </DialogContent>
          {addDialogProps.Actions || addDialogProps.onAdd ? (
            <DialogActions>
              {addDialogProps.Actions ? <addDialogProps.Actions row={addDialogData} type="add" close={closeAddDialog} /> : null}
              {addDialogProps.onAdd ? (
                <>
                  <Button onClick={() => closeAddDialog('close')}>Cancel</Button>
                  <Button variant="contained" color="success" onClick={() => addDialogProps.onAdd?.(addDialogData, closeAddDialog)}>Save</Button>
                </>
              ) : null}
            </DialogActions>
          ) : null}
        </Dialog>
      ) : null}
      {editDialogProps ? (
        <Dialog open={editDialogOpen} onClose={() => closeEditDialog('close')} maxWidth={editDialogProps.maxWidth || 'md'} fullWidth>
          <DialogTitle>{typeof editDialogProps.title === 'string' ? editDialogProps.title : <editDialogProps.title row={editDialogData} />}</DialogTitle>
          <DialogCloseButton handleClose={closeEditDialog} />
          <DialogContent>
            <editDialogProps.Content row={editDialogData} close={closeEditDialog} type="edit" />
          </DialogContent>
          {editDialogProps.Actions || editDialogProps.onEdit ? (
            <DialogActions>
              {editDialogProps.Actions ? <editDialogProps.Actions row={editDialogData} type="edit" close={closeEditDialog} /> : null}
              {editDialogProps.onEdit ? (
                <>
                  <Button onClick={() => closeEditDialog('close')}>Cancel</Button>
                  <Button variant="contained" color="success" onClick={() => editDialogProps.onEdit?.(editDialogData, closeEditDialog)}>Save</Button>
                </>
              ) : null}
            </DialogActions>
          ) : null}
        </Dialog>
      ) : null}
      {deleteDialogProps ? (
        <ConfirmDialog
          open={deleteDialogOpen}
          onClose={closeDeleteDialog}
          onConfirm={() => deleteDialogProps.onDelete(deleteDialogData, closeDeleteDialog)}
          buttonText="Delete"
          confirmColor="error"
          descriptionText={deleteDialogProps.description}
          titleText={deleteDialogProps.title}
        />
      ) : null}
    </Box>
  );
};

export default GenericTable;
