// export const AccessRoles = ['MONITOR'] as const;

import type { AccessRole, AccessRoleLevel as GeneratedAccessRoleLevel } from '../../generated/activityhelper/api.js';

// TODO Make table in activityhelper?!?
// export const AccessRole = {
//   MONITOR: 'MONITOR',
//   ERP: 'ERP',
//   MRP_PICK: 'MRP_PICK',
//   CONSTANT: 'CONSTANT',
// } as const;

// export type AccessRole = typeof AccessRoles[number];
// eslint-disable-next-line @typescript-eslint/no-redeclare
// export type AccessRole = typeof AccessRole[keyof typeof AccessRole];

// export const AccessRoleLevels = [
//   'READ',
//   'WRITE',
//   'ADMIN',
// ] as const;

export const AccessRoleLevel = {
  NONE: '',
  READ: 'READ',
  WRITE: 'WRITE',
  ADMIN: 'ADMIN',
} as const;

export const AccessRoleLevelMap: Record<Exclude<AccessRoleLevel, ''>, GeneratedAccessRoleLevel> = {
  NONE: 0,
  READ: 1,
  WRITE: 2,
  ADMIN: 3,
} as const;

export const ReadArray = [AccessRoleLevel.READ, AccessRoleLevel.WRITE, AccessRoleLevel.ADMIN] as string[];
export const WriteArray = [AccessRoleLevel.WRITE, AccessRoleLevel.ADMIN] as string[];

// export type AccessRoleLevel = typeof AccessRoleLevels[number] | '';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AccessRoleLevel = keyof typeof AccessRoleLevel | '';

export type UserAccessRoleProperty = { role: AccessRole; level: AccessRoleLevel, userId: number, id: number };
export type UserAccessRole = Pick<UserAccessRoleProperty, 'role' | 'level'>;

export const roleLevelToNumber = (level: AccessRoleLevel) => AccessRoleLevelMap[level];
export const compareRoleLevels = (
  current: AccessRoleLevel,
  levelToCompareTo: AccessRoleLevel,
) => (roleLevelToNumber(current) - roleLevelToNumber(levelToCompareTo)) >= 0;
