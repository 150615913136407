import type { UseQueryOptions } from '@tanstack/react-query';
import type { GetInstallItemUsageResponseInner } from '../../generated/activityhelper/api.js';
import { monitorApi } from '../apis/apis.js';

export const installItemUsagesQuery: UseQueryOptions<GetInstallItemUsageResponseInner[]> = {
  queryKey: ['installItemUsages'],
  queryFn: async () => {
    const { data } = await monitorApi.apiErpInstallItemUsageGet({});

    return data;
  },
  refetchOnWindowFocus: false,
};

export const empty = () => ({});
