import React from 'react';
import {
  createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Container } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/nb';
// import type { Theme } from '@mui/material/styles';
import SalesOverviewPage from './pages/SalesOverviewPage.js';
// import TestPage from './pages/TestPage.js';
import LoginPage from './pages/LoginPage.js';
import HourReportStatus from './pages/HourReportStatusPage.js';
import HourReportOverview from './pages/HourReportOverviewPage.js';
import Header from './Header.js';
import CpproUserProvider from '../providers/CpproUserProvider.js';
import OldSalesOverviewPage from './pages/OldSalesOverviewPage.js';
import MonitorOverviewPage from './pages/monitor/MonitorOverviewPage.js';
import MonitorLayout from './monitor/MonitorLayout.js';
import MonitorEmailPage from './pages/monitor/MonitorEmailPage.js';
import MonitorLeadPage from './pages/monitor/MonitorLeadPage.js';
import MonitorSmsPage from './pages/monitor/MonitorSmsPage.js';
import MonitorErpPage from './pages/monitor/MonitorErpPage.js';
import MonitorCalendarPage from './pages/monitor/MonitorCalendarPage.js';
import MonitorTasksPage from './pages/monitor/MonitorTasksPage.js';
import MonitorAdministrationPage from './pages/monitor/MonitorAdministrationPage.js';
import MonitorIncidentPage from './pages/monitor/MonitorIncidentPage.js';
import MonitorAlertingAdminPage from './pages/monitor/MonitorAlertingAdminPage.js';
import MonitorPolicyPage from './pages/monitor/MonitorPolicyPage.js';
import ERPInvoicing from './pages/erp/ERPInvoicing.js';
import ERPOverview from './pages/erp/ERPOverview.js';
import CPProTools from './pages/cppro/CPProTools.js';
import ERPTools from './pages/erp/ERPTools.js';
import MigrationHelpersPage from './pages/erp/MigrationHelpersPage.js';
import ERPMRPPickErrors from './pages/erp/ERPMRPPickErrors.js';
import MRPStockOverview from './pages/erp/mrp/MRPStockOverview.js';
import ERPErrors from './pages/erp/ERPErrors.js';
import PublicHolidayPage from './pages/date/PublicHolidayPage.js';
import ConstantPage from './pages/constant/ConstantPage.js';
import ERPInstallItemUsagePage from './pages/erp/installItemUsage/ERPInstallItemUsagePage.js';

const ReactQueryDevtoolsProduction = React.lazy(() => import('@tanstack/react-query-devtools/build/modern/production.js').then(
  (d) => ({
    default: d.ReactQueryDevtools,
  }),
));

// declare module '@mui/material-pigment-css' {
//   interface ThemeArgs {
//     theme: Theme;
//   }
// }

const isReport = import.meta.env.MODE === 'report';

const queryClient = new QueryClient();

const reportRoutes = [
  <Route key="/" path="/" element={<SalesOverviewPage />} />,
  <Route key="old" path="/old" element={<OldSalesOverviewPage />} />,
  <Route key="login" path="/login" element={<LoginPage />} />,
  <Route key="*" path="*" element={<Navigate to="/" replace />} />,
];

const cpproRoutes = [
  <Route key="/" path="/" element={<h6>Tom side...</h6>} />,
  <Route key="hourReport" path="/hourReport" element={<Navigate to="/hourReport/status" replace />} />,
  <Route key="hourReportStatus" path="/hourReport/status" element={<HourReportStatus />} />,
  <Route key="hourReportOverview" path="/hourReport/overview" element={<HourReportOverview />} />,
  <Route key="sales" path="/sales" element={<SalesOverviewPage />} />,
  <Route key="sales/old" path="/sales/old" element={<OldSalesOverviewPage />} />,
  <Route key="login" path="/login" element={<LoginPage />} />,
  <Route key="*" path="*" element={<Navigate to="/" replace />} />,
];

const noHeaderRoutes = [
  <Route key="mrp/stockOverview" path="/mrp/stockOverview" element={<MRPStockOverview />} />,
];

const monitoringRoutes = [
  <Route key="overview" path="overview" element={<MonitorOverviewPage />} />,
  <Route key="email" path="email" element={<MonitorEmailPage />} />,
  <Route key="sms" path="sms" element={<MonitorSmsPage />} />,
  <Route key="lead" path="lead" element={<MonitorLeadPage />} />,
  <Route key="erp" path="erp" element={<MonitorErpPage />} />,
  <Route key="calendar" path="calendar" element={<MonitorCalendarPage />} />,
  <Route key="tasks" path="tasks" element={<MonitorTasksPage />} />,
  <Route key="admin" path="admin" element={<MonitorAdministrationPage />} />,
  <Route key="incidents" path="incidents" element={<MonitorIncidentPage />} />,
  <Route key="incident" path="incident/:id" element={<MonitorIncidentPage />} />,
  <Route key="policies" path="policies" element={<MonitorPolicyPage />} />,
  <Route key="policy" path="policy/:id" element={<MonitorPolicyPage />} />,
  <Route key="alertAdmin" path="alertAdmin" element={<MonitorAlertingAdminPage />} />,
  <Route key="erpInvoicing" path="erpInvoicing" element={<ERPInvoicing />} />,
  <Route key="erpOverview" path="erpOverview" element={<ERPOverview />} />,
  <Route key="cpproTools" path="cpproTools" element={<CPProTools />} />,
  <Route key="erpTools" path="erpTools" element={<ERPTools />} />,
  <Route key="mrpPickErrors" path="mrpPickErrors" element={<ERPMRPPickErrors />} />,
  <Route key="migrationHelpers" path="migrationHelpers" element={<MigrationHelpersPage />} />,
  <Route key="erpErrors" path="erpErrors" element={<ERPErrors />} />,
  <Route key="publicHolidays" path="publicHolidays" element={<PublicHolidayPage />} />,
  <Route key="constants" path="constants" element={<ConstantPage />} />,
  <Route key="itemUsage" path="installItemUsage" element={<ERPInstallItemUsagePage />} />,
];

// const erpRoutes = [
// ];

const Layout = () => (
  <>
    <Header />
    <Outlet />
  </>
);

const OutletLayout = () => (
  <Outlet />
);

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route element={<Layout />}>{isReport ? reportRoutes : cpproRoutes}</Route>,
    <Route element={<OutletLayout />}>{noHeaderRoutes}</Route>,
    <Route key="monitor" path="/monitor" element={<MonitorLayout />}>{monitoringRoutes}</Route>,
    // <Route key="erp" path="/erp" element={<Layout />}>{erpRoutes}</Route>,
  ]),
  { basename: isReport ? undefined : '/react' },
);

const App = () => {
  const [showDevtools, setShowDevtools] = React.useState(false);
  React.useEffect(() => {
    // @ts-expect-error
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID!}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
          <CpproUserProvider>
            <Container maxWidth={false}>
              <RouterProvider router={router} />
            </Container>
          </CpproUserProvider>
        </LocalizationProvider>
      </GoogleOAuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      {showDevtools ? (
        <React.Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </React.Suspense>
      ) : null}
    </QueryClientProvider>
  );
};

export default App;
