import type { AutocompleteChangeReason } from '@mui/material';
import {
  Autocomplete, TextField, Box,
} from '@mui/material';
import type { Column } from '@tanstack/react-table';
import React, { useCallback, useMemo } from 'react';
import { removeFalsy } from '../../../../CPpro/Utils/commonUtils.js';

type AutocompleteFilterProps<T> = {
  column: Column<T>,
  label: string,
  options?: string[],
  minWidth?: number,
  addNullOption?: 'null' | '0',
  nullOptionLabel?: string,
};

const AutocompleteFilter = <T, >(props: AutocompleteFilterProps<T>) => {
  const {
    column, options, label, minWidth, addNullOption, nullOptionLabel,
  } = props;
  const filterValue = column.getFilterValue();
  const { setFilterValue } = column;

  const uniqueValues = column.getFacetedUniqueValues();

  const sortedUniqueValues = useMemo(() => Array.from(uniqueValues.keys()).filter(removeFalsy).sort(), [uniqueValues]);

  const additionalOption = useMemo<unknown[]>(() => {
    if (addNullOption === undefined) return [];

    switch (addNullOption) {
      case 'null':
        return ['null'];
      case '0':
        return [0];
      default:
        return [];
    }
  }, [addNullOption]);

  const onChange = useCallback((value: unknown, reason: AutocompleteChangeReason) => {
    if (reason === 'clear') {
      setFilterValue(undefined);
    } else if (addNullOption !== undefined) {
      if (value === 'null') {
        setFilterValue(null);
      } else {
        setFilterValue(value);
      }
    } else {
      setFilterValue(value);
    }
  }, [addNullOption, setFilterValue]);

  const actualFilterValue = useMemo(() => {
    if (addNullOption !== undefined) {
      if (filterValue === null) {
        return 'null';
      }
      return filterValue ?? '';
    }
    return filterValue ?? '';
  }, [addNullOption, filterValue]);

  return (
    <Box>
      <Autocomplete
        freeSolo
        getOptionLabel={(option) => ((typeof option === null || option === null || option === 'null') && addNullOption !== undefined ? (nullOptionLabel ?? '<null>') : ((option as undefined | { label?: string })?.label ?? option)) as string}
        value={actualFilterValue}
        onChange={(_, value, reason) => onChange(value, reason)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} label={label} />}
        options={additionalOption.concat(options ?? sortedUniqueValues)}
        fullWidth
        sx={{ minWidth: minWidth ?? 250 }}
      />
    </Box>
  );
};

export default AutocompleteFilter;
