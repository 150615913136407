import React from 'react';
import { CloudUpload } from '@mui/icons-material';
import { Button } from '@mui/material';

interface UploadButtonParams {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const UploadButton = ({ onChange }: UploadButtonParams) => (
  <Button
    variant="contained"
    color="primary"
    component="label"
    startIcon={<CloudUpload />}
  >
    Last opp
    <input
      type="file"
      hidden
      onChange={onChange}
    />
  </Button>
);

export default UploadButton;
