import React from 'react';
import type { SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Box, Button, Divider, IconButton, Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GenericTable, type DialogRowElementProps } from '../../../table/GenericTable.js';
import type { GetInstallItemUsageResponseInnerChangelogInner, GetInstallItemUsageResponseInnerAlternativesInner, GetInstallItemUsageResponseInner } from '../../../../../generated/activityhelper/api.js';
import { DateUtils } from '../../../../../sharedutils/index.js';
import { InstallItemUsageMutations } from '../../../../mutations/index.js';
import { InstallItemUsageQueries } from '../../../../queries/index.js';
import InstallItemUsageAlternativeAddDialog from './InstallItemUsageAlternativeAddDialog.js';
import InstallItemUsageAlternativeEditContent from './InstallItemUsageAlternativeEditContent.js';

const columnHelper = createColumnHelper<GetInstallItemUsageResponseInnerAlternativesInner>();
const changelogColumnHelper = createColumnHelper<GetInstallItemUsageResponseInnerChangelogInner>();

const columns = [
  columnHelper.accessor((a) => a.itemNumber, {
    header: 'Produkt nr',
    id: 'itemNumber',
  }),
  columnHelper.accessor((a) => a.description, {
    header: 'Beskrivelse',
    id: 'description',
  }),
  columnHelper.accessor((a) => a.questionId, {
    header: 'Spørsmåls ID',
    id: 'questionId',
  }),
  columnHelper.accessor((a) => a.questionName, {
    header: 'Spørsmålsnavn',
    id: 'questionName',
  }),
  columnHelper.accessor((a) => a.answer, {
    header: 'Svar',
    id: 'answer',
  }),
  columnHelper.accessor((a) => a.useSalesOrder, {
    header: 'Bruker salgsordre?',
    id: 'useSalesOrder',
  }),
  columnHelper.accessor((a) => a.quantityFactor, {
    header: 'Antallsfaktor',
    id: 'quantityFactor',
  }),
  columnHelper.accessor((a) => a.flatAddition, {
    header: 'Flatt tillegg',
    id: 'flatAddition',
  }),
  columnHelper.accessor((a) => a.storageUnitConversion, {
    header: 'Lagerenhetsfaktor',
    id: 'storageUnitConversion',
  }),
  columnHelper.accessor((a) => a.createdAt, {
    header: 'Opprettet',
    id: 'createdAt',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  columnHelper.accessor((a) => a.updatedAt, {
    header: 'Oppdatert',
    id: 'updatedAt',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  columnHelper.display({
    header: 'Actions',
    id: 'actions',
    cell: (ctx) => (
      <>
        <IconButton size="small" onClick={() => ctx.table.options.meta?.openEditDialog(ctx)}>
          <Edit color="warning" />
        </IconButton>
        <IconButton size="small" onClick={() => ctx.table.options.meta?.openDeleteDialog(ctx)}>
          <Delete color="error" />
        </IconButton>
      </>
    ),
  }),
];

const changelogColumns = [
  changelogColumnHelper.accessor((a) => a.field, {
    header: 'Felt',
    id: 'field',
  }),
  changelogColumnHelper.accessor((a) => a.oldValue, {
    header: 'Gammel verdi',
    id: 'oldValue',
  }),
  changelogColumnHelper.accessor((a) => a.newValue, {
    header: 'Ny verdi',
    id: 'newValue',
  }),
  changelogColumnHelper.accessor((a) => a.timestamp, {
    header: 'Endret',
    id: 'timestamp',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  changelogColumnHelper.accessor((a) => a.changedBy, {
    header: 'Endret av',
    id: 'changedBy',
  }),
];

const changelogInitialSort: SortingState = [{ id: 'timestamp', desc: true }];
const altInitialSort: SortingState = [{ id: 'itemNumber', desc: true }];

const InstallItemUsageInfoDialogContent = (props: DialogRowElementProps<GetInstallItemUsageResponseInner>) => {
  const { close, row } = props;
  const [altOpen, setAltOpen] = React.useState(false);

  const queryClient = useQueryClient();

  const deleteAlternativeMutation = useMutation({
    ...InstallItemUsageMutations.deleteItemUsageAlternativeMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(InstallItemUsageQueries.installItemUsagesQuery);
      close();
    },
  });

  if (!row) return null;

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Alternativ:</Typography>
      <Button variant="contained" color="success" sx={{ marginBottom: 2 }} onClick={() => setAltOpen(true)}>Legg til alternativ</Button>
      <GenericTable
        id="itemUsageAlternatives"
        columns={columns}
        data={row?.alternatives ?? []}
        deleteDialogProps={{
          title: 'Slett alternativ, er du sikker?',
          onDelete: (data) => (data ? deleteAlternativeMutation.mutateAsync({
            alternativeId: data.id.toString(),
            itemUsageId: data.itemUsageId.toString(),
          }) : undefined),
        }}
        editDialogProps={{
          title: 'Rediger alternativ',
          Content: InstallItemUsageAlternativeEditContent,
          onDialogClose: (action) => (action === 'submit' ? close() : undefined),
        }}
        initialSortingState={altInitialSort}
      />
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      <Typography variant="h6" gutterBottom>Changelog:</Typography>
      <GenericTable
        id="itemUsageChangelog"
        columns={changelogColumns}
        data={row?.changelog ?? []}
        pagination
        initialSortingState={changelogInitialSort}
      />
      <InstallItemUsageAlternativeAddDialog
        itemNumber={row.itemNumber}
        open={altOpen}
        onClose={(action) => {
          setAltOpen(false);
          if (action === 'submit') {
            close();
          }
        }}
      />
    </Box>
  );
};
export default InstallItemUsageInfoDialogContent;
