import { z } from 'zod';
import punycode from 'punycode.js';

export const emailSchema = z.string().transform(punycode.toASCII).pipe(z.string().min(1).email('Invalid email'));

export const stringSchema = z.union([z.string(), z.number()]).pipe(z.coerce.string());
export const numberSchema = z.union([z.string(), z.number()]).pipe(z.coerce.number());

export const stringIsJSONSchema = z.string().pipe(z.custom((str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}, 'Invalid JSON'));
export const stringToJSONSchema = z.string()
  .transform((str, ctx) => {
    try {
      return JSON.parse(str);
    } catch (error) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid JSON',
      });
      return z.NEVER;
    }
  });
export const jsonSchema = stringToJSONSchema.pipe(z.union([z.object({}), z.array(z.object({}))]));
