import React, { useCallback, useState } from 'react';
import {
  Checkbox, FormControlLabel, TextField, Grid2, Box,
} from '@mui/material';
import LoadingButton from '../common/buttons/LoadingButton.js';

const CreateMamutOrderForm = () => {
  const [cpproId, setCpproId] = useState('');
  const [cpproName, setCpproName] = useState('');
  const [acErpId, setACErpId] = useState('');
  const [act, setAct] = useState(true);

  const [advanced, setAdvanced] = useState(false);

  const onSubmit = useCallback(async () => {
    // eslint-disable-next-line no-alert
    alert('Ikke støttet lengre!');
  }, []);

  return (
    (
      <Box>
        <Grid2
          container
          direction="row"
          spacing={2}
          sx={{
            alignItems: 'center',
            marginBottom: 2,
          }}
        >
          <Grid2>
            <TextField
              value={cpproName}
              onChange={(e) => setCpproName(e.target.value)}
              title="CPPro Salgsordre"
              label="CPPro Salgsordre"
              disabled={!!cpproId || !!acErpId}
            />

          </Grid2>
          <Grid2>
            <TextField
              value={acErpId}
              onChange={(e) => setACErpId(e.target.value)}
              title="24SO AC(T) ID"
              label="24SO AC(T) ID"
              disabled={!!cpproId || !!cpproName}
            />
          </Grid2>
          <Grid2>
            <FormControlLabel label="Er ordre i ACT?" id="actLabel" control={<Checkbox checked={act} onChange={(_, checked) => setAct(checked)} />} />
          </Grid2>
          <Grid2>
            <FormControlLabel label="Avansert" id="advancedLabel" control={<Checkbox checked={advanced} onChange={(_, checked) => setAdvanced(checked)} />} />
          </Grid2>
          {advanced ? (
            <Grid2>
              <TextField
                value={cpproId}
                onChange={(e) => setCpproId(e.target.value)}
                title="Salgsordre ID"
                label="Salgsordre ID"
                disabled={!!acErpId || !!cpproName}
              />
            </Grid2>
          ) : null}
          <Grid2>
            <LoadingButton
              save
              buttonProps={{
                variant: 'contained',
                onClick: onSubmit,
              }}
            >
              Opprett mamut ordre
            </LoadingButton>
          </Grid2>
        </Grid2>
        {/* {isSuccess ? <Alert severity="success">Opprettet mamut ordre (sjekk cppro salgsordre for ordrenr - kan ta litt tid)</Alert> : null}
        {isError ? (
          <Alert severity="error">
            Kunne ikke opprette mamut ordre:
            <br />
            {JSON.stringify(error.response?.data ?? {}, undefined, 2)}
          </Alert>
        ) : null} */}
      </Box>
    )
  );
};

export default CreateMamutOrderForm;
