import React from 'react';
import type { ReactFormExtendedApi } from '@tanstack/react-form';
import { useForm } from '@tanstack/react-form';
import {
  Box, Grid2, IconButton, Stack, TextField, Tooltip,
  Typography,
} from '@mui/material';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import { Clear } from '@mui/icons-material';
import type { PostInstallItemUsageRequest, PutInstallItemUsageRequest } from '../../../../../generated/activityhelper/api.js';
import LoadingButton from '../../../common/buttons/LoadingButton.js';

type AddItemUsageWithAlternatives = PostInstallItemUsageRequest;
type UpdateItemUsage = PutInstallItemUsageRequest;

type InstallUsageAddEditFormProps<T extends 'edit' | 'add'> = {
  defaultData?: T extends 'add' ? AddItemUsageWithAlternatives : UpdateItemUsage;
  onSubmit: (data: T extends 'add' ? AddItemUsageWithAlternatives : UpdateItemUsage) => void;
  type: T;
};

const meterArray = Array(18).fill(1).map((a, idx) => (a as number) + 2 + idx);

const defaultQuantities = meterArray.map((meter) => ({
  meter,
  quantity: meter,
}));

const numberNullSchema = z.number().positive().nullable();

const InstallItemUsageAddEditForm = <T extends 'edit' | 'add', >(props: InstallUsageAddEditFormProps<T>) => {
  const { defaultData, onSubmit, type } = props;
  const form = useForm<T extends 'add' ? AddItemUsageWithAlternatives : UpdateItemUsage>({
    defaultValues: defaultData ?? ({ quantities: defaultQuantities.slice() } as (T extends 'add' ? AddItemUsageWithAlternatives : UpdateItemUsage)),
    onSubmit: ({ value }) => {
      onSubmit({
        ...value,
        discount: value.discount ? value.discount : null,
        staticQuantity: value.staticQuantity ? value.staticQuantity : null,
        flatAddition: value.flatAddition ? value.flatAddition : null,
      });
    },
  });

  const addForm = form as unknown as ReactFormExtendedApi<AddItemUsageWithAlternatives, undefined>;
  const editForm = form as unknown as ReactFormExtendedApi<UpdateItemUsage, undefined>;

  return (
    <Box sx={{ marginTop: 1 }}>
      <form onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      >
        <Grid2 container spacing={1} direction="column">
          <Grid2>
            <Typography gutterBottom>
              Den ser etter dette ved utrekning og bruker det som dukker opp først:
            </Typography>
            <ol>
              <li>
                statisk antall
              </li>
              <li>
                antall i tabellen over meter (3-20)
              </li>
              {/* <li>
                3. den følger antall meter brukt + flatt tillegg (om det finnes)
              </li> */}
            </ol>
            <Typography gutterBottom>
              Ulike alternativer har også mulighet for flere overstyringer som en faktor eller flatt tillegg.
            </Typography>
          </Grid2>
          {type === 'add' ? (
            <Grid2>
              <addForm.Field name="itemNumber" validatorAdapter={zodValidator()} validators={{ onChange: z.string().min(1) }}>
                {(field) => (
                  <TextField
                    label="Produktnr"
                    value={field.state.value ?? ''}
                    onChange={(e) => field.handleChange(e.target.value)}
                    error={!!field.state.meta.errors.length}
                    helperText={field.state.meta.errors.join(', ')}
                    required
                  />
                )}
              </addForm.Field>
            </Grid2>
          ) : null}
          {/* <Grid2>
            <addForm.Field
              name="flatAddition"
              validatorAdapter={zodValidator()}
              validators={{ onChange: z.optional(numberNullSchema) }}
            >
              {(field) => (
                <Stack direction="row">
                  <Tooltip title="Denne legg til et flatt tillegg i tillegg til antall meter (eks. 3 meter brukt + 0.5 flatt tillegg = 3.5 meter). Alternativ overskriver!">
                    <TextField
                      type="number"
                      label="Flatt tillegg"
                      value={field.state.value ?? ''}
                      onChange={(e) => field.handleChange(Number(e.target.value))}
                      error={!!field.state.meta.errors.length}
                      helperText={field.state.meta.errors.join(', ')}
                    />
                  </Tooltip>
                  <Tooltip title="Fjern"><IconButton onClick={() => field.handleChange(null)}><Clear /></IconButton></Tooltip>
                </Stack>
              )}
            </addForm.Field>
          </Grid2> */}
          <Grid2>
            <editForm.Field
              name="staticQuantity"
              validatorAdapter={zodValidator()}
              validators={{
                onChange: z.optional(numberNullSchema),
              }}
            >
              {(field) => (
                <Stack direction="row">
                  <Tooltip title="Blir denne satt er antall statisk og følger ikke antall meter brukt">
                    <TextField
                      type="number"
                      label="Statisk antall som blir lagt til"
                      value={field.state.value ?? ''}
                      onChange={(e) => field.handleChange(Number(e.target.value))}
                      error={!!field.state.meta.errors.length}
                      helperText={field.state.meta.errors.join(', ')}
                      sx={{ width: 350 }}
                    />
                  </Tooltip>
                  <Tooltip title="Fjern">
                    <span>
                      <IconButton disabled={!field.state.value} onClick={() => field.handleChange(null)}>
                        <Clear />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Stack>
              )}
            </editForm.Field>
          </Grid2>
          <Grid2>
            <Typography gutterBottom fontWeight="bold">Antall per meter (3-20):</Typography>
            <Typography>Om noen er tomme vil den følge meter / statisk antall dersom satt</Typography>
          </Grid2>
          <Grid2 container direction="row" alignItems="center">
            <addForm.Field name="quantities">
              {(field) => (
                <>

                  {meterArray.map((a) => {
                    const obj = field.state.value?.find((q) => q.meter === a);

                    return (
                      <Grid2 key={a}>
                        <TextField
                          type="number"
                          label={a.toString()}
                          value={obj?.quantity ?? ''}
                          onChange={(e) => field.handleChange([...field.state.value?.filter((q) => q.meter !== a) ?? [], {
                            // id: obj?.id,
                            meter: a,
                            quantity: Number(e.target.value),
                          }])}
                          error={!!field.state.meta.errors.length}
                          helperText={field.state.meta.errors.join(', ')}
                          key={a}
                          variant="outlined"
                          slotProps={{ inputLabel: { shrink: true } }}
                          sx={{ width: 70 }}
                        />
                      </Grid2>
                    );
                  })}
                  <Tooltip title="Tøm alle">
                    <span>
                      <IconButton
                        disabled={!field.state.value?.length}
                        onClick={() => field.handleChange([])}
                      >
                        <Clear />
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              )}
            </addForm.Field>
          </Grid2>
          <Grid2 container sx={{ justifyContent: 'flex-end' }}>
            <form.Subscribe selector={((state) => [state.canSubmit, state.isSubmitting])}>
              {([canSubmit, isSubmitting]) => (
                <LoadingButton
                  save
                  buttonProps={{
                    variant: 'contained',
                    type: 'submit',
                    loading: isSubmitting,
                    disabled: !canSubmit || isSubmitting,
                    color: 'success',
                  }}
                >
                  Lagre
                </LoadingButton>
              )}
            </form.Subscribe>
          </Grid2>
        </Grid2>
      </form>
    </Box>
  );
};

export default InstallItemUsageAddEditForm;
